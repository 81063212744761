import React from "react";

import testImage from "../../images/testimonial/testimonialImage1.png";

const CircleMarquee = () => {
  const range = (min, max) =>
    Array.from({ length: max - min + 1 }, (_, i) => min + i);

  return (
    <div className="w-full h-full mt-5  bg-gradient-to-t from-transparent to-white">
      <div className="relative flex overflow-x-hidden">
        <div className="py-1 w-full animate-marquee whitespace-nowrap grid grid-cols-8">
          {range(0, 7).map((x, i) => (
            <img
              key={i}
              src={`https://iwc-app-bucket.s3.ap-southeast-1.amazonaws.com/images/students/iwc-student-${x}.jpg`}
              className="rounded-full md:w-32 md:h-32 w-9 h-9 object-cover"
              alt={`student-${i}`}
            />
          ))}
        </div>
        <div className="absolute w-full top-0 py-1 animate-marquee2 whitespace-nowrap grid grid-cols-9">
          {range(8, 15).map((x, i) => (
            <img
              key={i}
              src={`https://iwc-app-bucket.s3.ap-southeast-1.amazonaws.com/images/students/iwc-student-${x}.jpg`}
              className="rounded-full md:w-32 md:h-32 w-9 h-9 object-cover"
              alt={`student-${i}`}
            />
          ))}
        </div>
      </div>
      <div className="relative flex overflow-x-hidden mt-5">
        <div className="py-1 w-full animate-marquee3 whitespace-nowrap grid grid-cols-8">
          {range(16, 23).map((x, i) => (
            <img
              key={i}
              src={`https://iwc-app-bucket.s3.ap-southeast-1.amazonaws.com/images/students/iwc-student-${x}.jpg`}
              className="rounded-full md:w-32 md:h-32 w-9 h-9 object-cover"
              alt={`student-${i}`}
            />
          ))}
        </div>
        <div className="absolute w-full top-0 py-1 animate-marquee4 whitespace-nowrap grid grid-cols-8">
          {range(24, 31).map((x, i) => (
            <img
              key={i}
              src={`https://iwc-app-bucket.s3.ap-southeast-1.amazonaws.com/images/students/iwc-student-${x}.jpg`}
              className="rounded-full md:w-32 md:h-32 w-9 h-9 object-cover"
              alt={`student-${i}`}
            />
          ))}
        </div>
      </div>
      <div className="relative flex overflow-x-hidden mt-5">
        <div className="py-1 w-full animate-marquee whitespace-nowrap grid grid-cols-8">
          {range(32, 39).map((x, i) => (
            <img
              key={i}
              src={`https://iwc-app-bucket.s3.ap-southeast-1.amazonaws.com/images/students/iwc-student-${x}.jpg`}
              className="rounded-full md:w-32 md:h-32 w-9 h-9 object-cover"
              alt={`student-${i}`}
            />
          ))}
        </div>
        <div className="absolute w-full top-0 py-1 animate-marquee2 whitespace-nowrap grid grid-cols-8">
          {range(40, 47).map((x, i) => (
            <img
              key={i}
              src={`https://iwc-app-bucket.s3.ap-southeast-1.amazonaws.com/images/students/iwc-student-${x}.jpg`}
              className="rounded-full md:w-32 md:h-32 w-9 h-9 object-cover"
              alt={`student-${i}`}
            />
          ))}
        </div>
      </div>
      <div className="relative flex overflow-x-hidden mt-5">
        <div className="py-1 w-full animate-marquee3 whitespace-nowrap grid grid-cols-8">
          {range(48, 55).map((x, i) => (
            <img
              key={i}
              src={`https://iwc-app-bucket.s3.ap-southeast-1.amazonaws.com/images/students/iwc-student-${x}.jpg`}
              className="rounded-full md:w-32 md:h-32 w-9 h-9 object-cover"
              alt={`student-${i}`}
            />
          ))}
        </div>
        <div className="absolute w-full top-0 py-1 animate-marquee4 whitespace-nowrap grid grid-cols-8">
          {range(56, 63).map((x, i) => (
            <img
              key={i}
              src={`https://iwc-app-bucket.s3.ap-southeast-1.amazonaws.com/images/students/iwc-student-${x}.jpg`}
              className="rounded-full md:w-32 md:h-32 w-9 h-9 object-cover"
              alt={`student-${i}`}
            />
          ))}
        </div>
      </div>
      <div className="relative flex overflow-x-hidden mt-5">
        <div className="py-1 w-full animate-marquee whitespace-nowrap grid grid-cols-8">
          {range(64, 71).map((x, i) => (
            <img
              key={i}
              src={`https://iwc-app-bucket.s3.ap-southeast-1.amazonaws.com/images/students/iwc-student-${x}.jpg`}
              className="rounded-full md:w-32 md:h-32 w-9 h-9 object-cover"
              alt={`student-${i}`}
            />
          ))}
        </div>
        <div className="absolute w-full top-0 py-1 animate-marquee2 whitespace-nowrap grid grid-cols-8">
          {range(72, 79).map((x, i) => (
            <img
              key={i}
              src={`https://iwc-app-bucket.s3.ap-southeast-1.amazonaws.com/images/students/iwc-student-${x}.jpg`}
              className="rounded-full md:w-32 md:h-32 w-9 h-9 object-cover"
              alt={`student-${i}`}
            />
          ))}
        </div>
      </div>
    </div>
  );
};

export default CircleMarquee;
