import React from "react";

const VideoMarquee = () => {
  const range = (min, max) =>
    Array.from({ length: max - min + 1 }, (_, i) => min + i);
  return (
    <div className="w-full">
      <div className="relative flex overflow-x-hidden">
        <div className="py-12 w-full animate-marquee whitespace-nowrap grid grid-cols-3">
          {range(0, 2).map((x, i) => (
            <span className="">
              <video className="w-3/4 border-10" controls>
                <source
                  src={`https://iwc-app-bucket.s3.ap-southeast-1.amazonaws.com/images/videos/testimonial-videos-${x}.mp4`}
                  type="video/mp4"
                />
                Your browser does not support the video tag.
              </video>
            </span>
          ))}
        </div>
        <div className="absolute w-full top-0 py-12 animate-marquee2 whitespace-nowrap grid grid-cols-3">
          {range(3, 5).map((x, i) => (
            <span>
              <video className="w-3/4" controls>
                <source
                  src={`https://iwc-app-bucket.s3.ap-southeast-1.amazonaws.com/images/videos/testimonial-videos-${x}.mp4`}
                  type="video/mp4"
                />
                Your browser does not support the video tag.
              </video>
            </span>
          ))}
        </div>
      </div>
    </div>
  );
};

export default VideoMarquee;
