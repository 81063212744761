import React from "react";
import PhoneVideoMarquee from "./mini/PhoneVideoMarquee";
import VideoMarquee from "./mini/VideoMarquee";
import CircleMarquee from "./mini/CircleMarquee";

const TestimonialsTwo = () => {
  return (
    <div className="w-full h-full pt-5 mb-10 my-20 text-center">
      <div className="">
        <h2 className="md:text-5xl text-3xl p-2 font-bcneb ">
          A <span className="text-mgreen">STRONG COMMUNITY</span> AND SUPPORT
          SYSTEM!
        </h2>
      </div>
      <div className="w-full">
        <div>
          <CircleMarquee />
        </div>
      </div>
    </div>
  );
};

export default TestimonialsTwo;
