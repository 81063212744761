import React from "react";
import { useUpdateUserToSubscribeMutation } from "../slices/usersApiSlice";
import { useCheckIfPaidMutation } from "../slices/paymentsApiSlice";
import { useDispatch } from "react-redux";
import { setCredentials } from "../slices/authSlice";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";

export const PaymentSuccess = () => {
  const { userInfo } = useSelector((state) => state.auth);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [checkOutPaid] = useCheckIfPaidMutation();
  const [updateUserToSubscribe] = useUpdateUserToSubscribeMutation();

  const verify = async () => {
    try {
      const res = await checkOutPaid(userInfo.email).unwrap();
      if (res.paid === true) {
        const res2 = await updateUserToSubscribe().unwrap();
        dispatch(setCredentials(res2));
        toast("Subscription Successful");
        navigate("/profile");
      } else {
        toast.error("Payment not successful");
        navigate("/join");
      }
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <div className="flex flex-col w-full h-screen place-items-center my-20">
      <div className="w-full flex flex-col justify-center items-center text-white font-bcnsb">
        <div className="w-full flex justify-center my-5">
          <div className="md:w-1/2 h-full w-3/4 border place-items-start bg-[conic-gradient(#03D38B,#10917B,#356C59,#03D38B)] flex flex-col shadow-lg rounded-lg p-5">
            <h2 className="text-5xl text-left my-10">Thank you for paying!</h2>
            <button
              className="
              w-full h-16 text-white text-left place-self-center items-center text-xl flex p-4 font-bcnsb bg-[conic-gradient(#03D38B,#10917B,#356C59,#400E32,#A61F69,#C5478C,#8CEBCB,#03D38B)] rounded-lg"
              onClick={verify}
            >
              Click here to unlock content!
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PaymentSuccess;
