import React from "react";

export function FAQAccordion() {
  return (
    <>
      <div className="p-10">
        <section
          id="faq"
          className="grid grid-cols-1 md:p-10 p-5 text-center mb-10 bg-grey rounded-lg font-bcnsb"
        >
          <h2 className="md:text-5xl text-3xl font-bcneb text-left p-2 ">
            FAQs
          </h2>
          <div className="m-2 font-bcnsb space-y-2">
            <div
              className="group flex flex-col gap-2 rounded-lg bg-white p-5 text-black"
              tabindex="1"
            >
              <div className="flex cursor-pointer items-center justify-between">
                <span className="md:text-2xl text-lg font-bcneb pt-2 text-left">
                  {" "}
                  How fast will I see returns?{" "}
                </span>
                <img
                  src="https://upload.wikimedia.org/wikipedia/commons/e/e9/Arrow_bottom.png"
                  className="h-2 w-3 transition-all duration-500 group-focus:-rotate-180"
                />
              </div>
              <div className="invisible h-auto max-h-0 items-start text-left opacity-0 transition-all group-focus:visible group-focus:max-h-screen group-focus:opacity-100 group-focus:duration-1000">
                Trading with certainty is a method based entirely on technical
                analysis & price action. Once you follow our stringent
                checklist, you will start seeing returns immediately.
              </div>
            </div>

            <div
              className="group flex flex-col gap-2 rounded-lg bg-white p-5 text-black"
              tabindex="2"
            >
              <div className="flex cursor-pointer items-center justify-between">
                <span className="md:text-2xl text-lg font-bcneb pt-2 text-left">
                  {" "}
                  How long will it take for me to be consistently profitable?
                </span>
                <img
                  src="https://upload.wikimedia.org/wikipedia/commons/e/e9/Arrow_bottom.png"
                  className="h-2 w-3 transition-all duration-500 group-focus:-rotate-180"
                />
              </div>
              <div className="invisible h-auto max-h-0 items-start text-left opacity-0 transition-all group-focus:visible group-focus:max-h-screen group-focus:opacity-100 group-focus:duration-1000">
                It is subjective to each individual. But based on our own
                statistics, anybody can achieve a decent level of consistency
                within a 3 - 6 month period. Provided they follow the given
                blueprint.
              </div>
            </div>
            <div
              className="group flex flex-col gap-2 rounded-lg bg-white p-5 text-black"
              tabindex="2"
            >
              <div className="flex cursor-pointer items-center justify-between">
                <span className="md:text-2xl text-lg font-bcneb pt-2 text-left">
                  {" "}
                  Will I be able to understand the techniques and methods taught
                  in the masterclass?
                </span>
                <img
                  src="https://upload.wikimedia.org/wikipedia/commons/e/e9/Arrow_bottom.png"
                  className="h-2 w-3 transition-all duration-500 group-focus:-rotate-180"
                />
              </div>
              <div className="invisible h-auto max-h-0 items-start text-left opacity-0 transition-all group-focus:visible group-focus:max-h-screen group-focus:opacity-100 group-focus:duration-1000">
                Having mentored people of different age groups, we have come up
                with a step by step guideline, that caters to the young and old.
                This means that you can be a complete beginner with zero trading
                knowledge and start trading with success in no time at all.
              </div>
            </div>

            <div
              className="group flex flex-col gap-2 rounded-lg bg-white p-5 text-black"
              tabindex="2"
            >
              <div className="flex cursor-pointer items-center justify-between">
                <span className="md:text-2xl text-lg font-bcneb pt-2 text-left">
                  {" "}
                  I do not have a lot of capital. Is it possible for me to start
                  trading?
                </span>
                <img
                  src="https://upload.wikimedia.org/wikipedia/commons/e/e9/Arrow_bottom.png"
                  className="h-2 w-3 transition-all duration-500 group-focus:-rotate-180"
                />
              </div>
              <div className="invisible h-auto max-h-0 items-start text-left opacity-0 transition-all group-focus:visible group-focus:max-h-screen group-focus:opacity-100 group-focus:duration-1000">
                The honest truth is that you can start trading with just a few
                hundred dollars. We trade actively in the US Markets. However,
                our suggested amount to start trading is at least USD$1000.
              </div>
            </div>
            <div
              className="group flex flex-col gap-2 rounded-lg bg-white p-5 text-black"
              tabindex="2"
            >
              <div className="flex cursor-pointer items-center justify-between">
                <span className="md:text-2xl text-lg font-bcneb pt-2 text-left">
                  {" "}
                  How can I join the 1K A Day Club?
                </span>
                <img
                  src="https://upload.wikimedia.org/wikipedia/commons/e/e9/Arrow_bottom.png"
                  className="h-2 w-3 transition-all duration-500 group-focus:-rotate-180"
                />
              </div>
              <div className="invisible h-auto max-h-0 items-start text-left opacity-0 transition-all group-focus:visible group-focus:max-h-screen group-focus:opacity-100 group-focus:duration-1000">
                You can join the 1K a day club by attending the free IWC
                Masterclass followed by our IWC Virtual Bootcamp as these are
                the pre-requisites to join the 1K A Day Club.
              </div>
            </div>
            <div
              className="group flex flex-col gap-2 rounded-lg bg-white p-5 text-black"
              tabindex="2"
            >
              <div className="flex cursor-pointer items-center justify-between">
                <span className="md:text-2xl text-lg font-bcneb pt-2 text-left">
                  {" "}
                  How can I attend the free IWC Masterclass?{" "}
                </span>
                <img
                  src="https://upload.wikimedia.org/wikipedia/commons/e/e9/Arrow_bottom.png"
                  className="h-2 w-3 transition-all duration-500 group-focus:-rotate-180"
                />
              </div>
              <div className="invisible h-auto max-h-0 items-start text-left opacity-0 transition-all group-focus:visible group-focus:max-h-screen group-focus:opacity-100 group-focus:duration-1000">
                You can join us by clicking here and filling up the form. This
                will let us know your interest of attending the free
                masterclass.
              </div>
            </div>
          </div>
        </section>
      </div>
    </>
  );
}

export default FAQAccordion;
