import React, { useEffect, useState } from "react";
import testImage from "../../images/trade-shadow.png";
import "./MiniComponents.css";
import TradeUp from "../../images/tradeup.png";

const PhoneTradeMarquee = () => {
  // const [tickerData, setTickerData] = useState([]);
  const [tickerList, setTickerList] = useState([]);

  const getJson = () => {
    return fetch(`${process.env.REACT_APP_TICKER_DATA}`)
      .then((response) => {
        return response.json();
      })
      .catch((error) => {
        console.error(error);
      });
  };

  useEffect(() => {
    getJson().then((data) => {
      setTickerList(data);
    });
  }, []);

  const firstHalf = tickerList.slice(0, 8);
  const secondHalf = tickerList.slice(8, 16);

  return (
    <div className="w-full h-full mt-5 bg-gradient-to-t from-transparent to-white">
      <div className="relative flex">
        <div className="py-1 w-full  grid grid-cols-2 grid-rows-3 gap-5 ">
          {firstHalf.length > 0 ? (
            firstHalf.map((ticker, index) => (
              <div className="text-black font-bcnbook shadow shadow-mgreen p-3 rounded-lg">
                <div
                  key={index}
                  className="text-left items-center grid gird-rows-4"
                >
                  <p className="ml-2">
                    Ticker: <span className="font-bcneb"> {ticker.ticker}</span>
                  </p>
                  <p className="ml-2">
                    Type:
                    <span className="font-bcneb"> {ticker.tickertype}</span>
                  </p>
                  <p className="ml-2">
                    Profit:
                    <span className="font-bcneb"> ${ticker.profit}</span>
                  </p>
                  <p className="ml-2">
                    Duration:
                    <span className="font-bcneb"> {ticker.duration}</span>
                  </p>
                  <p className="ml-2">
                    Date:<span className="font-bcneb"> {ticker.date}</span>
                  </p>
                </div>
                <div className="flex flex-row items-center w-full bg-lgreen rounded-lg p-1">
                  <p className="ml-2 justify-start">
                    ROI:<span className="font-bcneb"> {ticker.roi} % </span>
                  </p>
                  <img
                    src={TradeUp}
                    alt="trade green"
                    className="justify-self-end place-self-center w-5 h-5 mx-auto p-1"
                  />
                </div>
              </div>
            ))
          ) : (
            // <div key={index} className="flex items-center">
            <p>Loading...</p>
          )}
        </div>
      </div>
    </div>
  );
};

export default PhoneTradeMarquee;
