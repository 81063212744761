import React from "react";
import { useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import { useGetReportByIdQuery } from "../slices/reportsApiSlice";
import { pdfjs } from "react-pdf";
import PDFViewer from "../components/mini/PDFViewer";
import "react-pdf/dist/esm/Page/TextLayer.css";
import "react-pdf/dist/esm/Page/AnnotationLayer.css";

pdfjs.GlobalWorkerOptions.workerSrc = `//unpkg.com/pdfjs-dist@${pdfjs.version}/build/pdf.worker.min.js`;

const ReportScreen = () => {
  const { id } = useParams();
  const { userInfo } = useSelector((state) => state.auth);

  const {
    data: report,
    isSuccess,
    isLoading,
    isError,
  } = useGetReportByIdQuery({
    id,
  });

  const options = {
    weekday: "long",
    year: "numeric",
    month: "long",
    day: "numeric",
  };

  return (
    <div className="mb-20 pb-20">
      <section className="flex flex-col font-bcnsb text-black">
        <h2 className="text-3xl md:text-5xl text-center font-bcneb mb-5 mt-10">
          Report
        </h2>
        {userInfo && userInfo.isSubscribed ? (
          <>
            {isLoading ? (
              <p>Loading...</p>
            ) : isError ? (
              <p>Error fetching report</p>
            ) : isSuccess ? (
              <>
                <div className="md:flex md:flex-col items-center hidden justify-center p-5 rounded-md ">
                  <h4 className="text-lg md:text-2xl font-bcneb mb-5">
                    {new Date(report.date).toLocaleDateString("en-US", options)}
                  </h4>
                  <div className="w-full justify-center items-center flex flex-col">
                    <PDFViewer samplePDF={report.reportFile} />
                  </div>
                </div>
                <div className="flex flex-row justify-center">
                  <p className="text-lg h-60 md:text-xl md:hidden flex font-bcnsb text-center p-10">
                    SEE ON DESKTOP TO VIEW PDF
                  </p>
                </div>
              </>
            ) : (
              <></>
            )}
          </>
        ) : (
          <>
            <h2 className="text-xl md:text-3xl text-center font-bcneb">
              You need to subscribe to view this report
            </h2>
          </>
        )}
      </section>
    </div>
  );
};

export default ReportScreen;
