import React from "react";
import discordLogo from "../images/discord.png";
import facebookLogo from "../images/facebook.png";
import instagramLogo from "../images/instagram.png";
import whatsappLogo from "../images/whatsapp.png";
import iwcLogo from "../images/iwc-logo.png";
import ReactWhatsapp from "react-whatsapp";

const Footer = () => {
  return (
    <>
      <footer className="">
        <div className="w-full bg-mgreen font-bcnsb">
          <section
            id="footer-social"
            className="w-full h-32 grid grid-cols-8 gap-3 content-center text-gray-200 p-5"
          >
            <div className="text-white self-center col-span-2">
              <hr></hr>
            </div>
            <a
              href="https://discord.com/invite/cXuG6SwT"
              className="w-8 place-self-center"
            >
              <img className="object-contain" href="" src={discordLogo}></img>
            </a>
            <a
              href="https://www.facebook.com/investwithcertainty?mibextid=ZbWKwL"
              className="w-8 place-self-center"
            >
              <img className="object-contain" src={facebookLogo}></img>
            </a>
            <a
              href="https://www.instagram.com/investwithcertainty?igsh=MWZtMTFtM3dqYWtvMQ=="
              className="w-8 place-self-center"
            >
              <img className="object-contain" src={instagramLogo}></img>
            </a>
            <ReactWhatsapp
              className="w-8 place-self-center"
              number="+65 8784 3611"
              message="Hi! I would like to know more about IWC and 1K Club."
            >
              <img className="object-contain" src={whatsappLogo}></img>
            </ReactWhatsapp>

            <div className="text-white self-center col-span-2">
              <hr></hr>
            </div>
          </section>
          <section
            id="footer-links"
            className="text-white grid grid-rows-3 px-5"
          >
            <div className="place-self-center">
              <img src={iwcLogo}></img>
            </div>
            <div className="place-self-center text-center">
              <p>© 2024 Invest With Certainty</p>
              <p>All Rights Reserved</p>
            </div>
            <div className="grid grid-cols-3 place-items-center text-center">
              <div className="place-self-center">
                <a className="underline " href="/privacy">
                  Privacy Policy
                </a>
              </div>
              <div className="place-self-center">
                <a className="underline " href="/terms">
                  Terms and Conditions
                </a>
              </div>
              <div className="place-self-center">
                <a className="underline " href="/contact">
                  Contact Us
                </a>
              </div>
            </div>
          </section>
        </div>
      </footer>
    </>
  );
};

export default Footer;
