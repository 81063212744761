import React from "react";
import { useSelector } from "react-redux";
import {
  useGetReportsQuery,
  useCreateReportMutation,
  useUploadReportMutation,
  useDeleteReportMutation,
} from "../slices/reportsApiSlice.js";
import { useState } from "react";
import { toast } from "react-toastify";
import { useCheckSubscriptionStatusMutation } from "../slices/paymentsApiSlice";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { setCredentials } from "../slices/authSlice.js";
import { useEffect } from "react";

const ReportsList = () => {
  const { userInfo } = useSelector((state) => state.auth);
  const [report, setReport] = useState("");
  const [date, setDate] = useState(null);
  const [month, setMonth] = useState(null);
  const [year, setYear] = useState(null);

  const { data: reports, isSuccess, isLoading, isError } = useGetReportsQuery();
  const [reportList, setReportList] = useState([]);
  const [checkSubscription] = useCheckSubscriptionStatusMutation();
  const [createReport] = useCreateReportMutation();
  const [uploadReport] = useUploadReportMutation();
  const [deleteReport] = useDeleteReportMutation();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const options = {
    weekday: "long",
    year: "numeric",
    month: "long",
    day: "numeric",
  };

  useEffect(() => {
    if (!userInfo) {
      navigate("/login");
    }
  }, [userInfo]);

  useEffect(() => {
    if (isSuccess && month === null) {
      setReportList(reports);
    } else if (month === undefined || year === undefined) {
      setReportList(reports);
    } else {
      setReportList(
        reportList.filter((report) => {
          const reportDate = new Date(report.date);
          return (
            reportDate.getMonth() + 1 === parseInt(month) &&
            reportDate.getFullYear() === parseInt(year)
          );
        })
      );
    }
  }, [isSuccess, month, year]);

  const handleReportUpload = async (e) => {
    const formData = new FormData();
    const file = e.target.files[0];
    formData.append("report", file);
    try {
      const { data } = await uploadReport(formData);
      setReport(data.data);
      toast.success("Report stored successfully");
    } catch (error) {
      toast.error("Report storing failed");
    }
  };

  const handleReportCreate = async (e) => {
    e.preventDefault();
    try {
      const body = {
        reportFile: report,
        date,
      };
      const res = await createReport(body);
      toast.success("Report created successfully");
    } catch (error) {
      toast.error("Report creation failed");
    }
  };

  const handleReportDelete = async (id) => {
    try {
      const res = await deleteReport({ id });
      toast.success("Report deleted successfully");
    } catch (error) {
      toast.error("Report deletion failed");
    }
  };

  const handleViewReport = async (id) => {
    //check if user has an active subscription
    //if not, redirect to /join
    //else, redirect to /reports/:id

    const data = {
      customerId: userInfo.stripeCustomer,
    };
    const res = await checkSubscription(data).unwrap();
    if (res.isSubscribed) {
      window.location.href = `/reports/${id}`;
    } else {
      toast.error("Subscription required to view reports");
      dispatch(setCredentials({ ...res }));
      window.location.href = "/join";
    }
  };

  return (
    <div className="w-full flex flex-col items-center justify-center mt-10 mb-20 pb-20">
      <h2 className="text-4xl md:text-5xl text-center font-bcneb mt-20 mb-10">
        Weekly Reports
      </h2>
      {userInfo && userInfo.isAdmin ? (
        <section
          id="uploadReport"
          className="flex flex-row items-center font-bcnsb text-black text-center mb-10"
        >
          <div className="flex flex-col items-center justify-center">
            <h2 className="text-xl md:text-3xl text-center font-bcneb">
              Upload Report
            </h2>
            <form
              onSubmit={(e) => handleReportCreate(e)}
              className="flex flex-col font-bcnsb gap-3 items-center justify-center p-5 w-full md:w-3/4 rounded-md"
            >
              <input
                type="file"
                id="report"
                name="report"
                onChange={(e) => handleReportUpload(e)}
                placeholder="Upload Report"
                className="w-96 font-bcnbook rounded-md p-2 focus:outline-none focus:ring-2 focus:ring-lgreen focus:border-transparent"
              />
              <input
                type="date"
                id="date"
                name="date"
                onChange={(e) => setDate(e.target.value)}
                placeholder="Enter Date"
                className="w-96 font-bcnbook rounded-md p-2 focus:outline-none focus:ring-2 focus:ring-lgreen focus:border-transparent"
              />

              <button
                type="submit"
                className="bg-lgreen text-white font-bcnsb py-2 w-full rounded-md bg-[conic-gradient(#03D38B,#10917B,#356C59,#400E32,#A61F69,#C5478C,#8CEBCB,#03D38B)]"
              >
                Upload
              </button>
            </form>
          </div>
        </section>
      ) : (
        <></>
      )}

      <section
        id="viewReports"
        className="flex flex-col items-center justify-center font-bcnsb text-black text-center mb-10"
      >
        <h2 className="text-xl md:text-3xl text-center font-bcneb mb-5">
          View Reports
        </h2>
        <input
          datepicker
          type="month"
          className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full mb-10 ps-10 p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
          placeholder="Select date"
          onChange={(e) => {
            setReportList(reports);
            const [newYear, newMonth] = e.target.value.split("-");
            console.log(newYear, newMonth);
            setMonth(newMonth);
            setYear(newYear);
          }}
        />

        <div className="flex flex-col md:grid md:grid-cols-3 w-full items-center justify-center gap-5">
          {reportList.map((report) => (
            <div
              key={report._id}
              className="flex flex-col items-center justify-center p-5 rounded-md border shadow"
            >
              <h4 className="text-lg md:text-2xl font-bcneb mb-5">
                {new Date(report.date).toLocaleDateString("en-US", options)}
              </h4>
              <button
                onClick={() => handleViewReport(report._id)}
                className="text-lg md:text-xl w-full font-bcnsb rounded-lg p-2 text-white text-center hover:duration-300 transition hover:bg-black hover:text-white bg-lgreen"
              >
                View Report
              </button>
              {userInfo && userInfo.isAdmin ? (
                <button
                  onClick={() => handleReportDelete(report._id)}
                  className="bg-magenta font-bcnsb text-white pb-2 pt-3 w-full rounded-lg hover:bg-black mt-5"
                >
                  Delete
                </button>
              ) : (
                <></>
              )}
            </div>
          ))}
        </div>
      </section>
    </div>
  );
};

export default ReportsList;
