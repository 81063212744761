import React from "react";

export const PaymentFailed = () => {
  return (
    <div className="flex flex-col w-full h-screen place-items-center">
      <div className="w-full flex flex-col justify-center items-center text-white font-bcnsb">
        <div className="w-full flex justify-center my-5">
          <div className="md:w-1/5 h-full w-3/4 border place-items-start bg-[conic-gradient(#400E32,#A61F69,#C5478C,#400E32)] flex flex-col shadow-lg rounded-lg p-5">
            <div className="w-full flex justify-start">
              <h2 className="text-5xl text-left">Payment Failed</h2>
            </div>
            <div className="w-full flex flex-col justify-start mt-4">
              <p className="text-xl">Please try again later</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PaymentFailed;
