import React, { useState } from "react";
import { useSelector } from "react-redux";
import { useLogoutMutation } from "../slices/usersApiSlice";
import { logout } from "../slices/authSlice";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import IWClogo from "../images/iwc-logo-large.png";
import OneKClubLogo from "../images/1KLogo.png";
import { useCancelSubscriptionMutation } from "../slices/paymentsApiSlice";
import { toast } from "react-toastify";
import { useGetContactsQuery } from "../slices/contactsApiSlice";
import * as XLSX from "xlsx";

const ProfileScreen = () => {
  const { userInfo } = useSelector((state) => state.auth);
  const { data: contacts, isLoading, isError } = useGetContactsQuery();
  const [userLogout] = useLogoutMutation();
  const [cancelSub] = useCancelSubscriptionMutation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const options = {
    weekday: "long",
    year: "numeric",
    month: "long",
    day: "numeric",
  };

  const logoutHandler = async () => {
    try {
      await userLogout().unwrap();
      dispatch(logout());
      navigate("/login");
    } catch (err) {
      console.log(err);
    }
  };

  const cancelHandler = async () => {
    try {
      const res = await cancelSub(userInfo.email).unwrap();
      toast("Subscription Cancelled: Access Remains till end of Billing Cycle");
      navigate("/");
    } catch (err) {
      console.log(err);
      toast("You have already cancelled" + err);
    }
  };

  const exportContactsHandler = async () => {
    try {
      const contactArray = [];
      contacts?.map((contact) => {
        contactArray.push({
          Name: contact.name,
          Email: contact.email,
          Phone: contact.phone,
        });
      });
      const worksheet = XLSX.utils.json_to_sheet(contactArray);
      const workbook = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(workbook, worksheet, "Contacts");
      XLSX.writeFile(workbook, "IWC-APP-Contacts.xlsx");
      toast.success("Contacts Exported");
    } catch (err) {
      console.log(err);
      toast.error("Error Exporting Contacts");
    }
  };

  return (
    <div className="w-full flex flex-col justify-center items-center text-white font-bcnsb mt-10 mb-20 pb-20">
      <div className="w-full flex justify-center my-5">
        <div className="md:w-1/5 h-full w-3/4 border place-items-start bg-[conic-gradient(#03D38B,#10917B,#356C59,#03D38B)] flex flex-col shadow-lg rounded-lg p-5">
          <div className="w-full flex justify-start">
            <h2 className="text-5xl text-left px-2">
              {userInfo && userInfo.name}
            </h2>
          </div>
          <div className="w-full flex flex-col justify-start mt-4">
            <p className="text-xl px-2">{userInfo && userInfo.email}</p>
            <p className="text-sm px-2">
              Member since:{" "}
              {userInfo && userInfo.dateJoined
                ? new Date(userInfo.dateJoined).toLocaleDateString(
                    "en-US",
                    options
                  )
                : new Date().toLocaleDateString("en-US", options)}
            </p>
            {userInfo && userInfo.isSubscribed ? (
              <>
                <p className="px-2">Subscribed</p>
                <button
                  onClick={cancelHandler}
                  className="border w-1/2 pt-2 pb-1 px-2 mt-2 text-left rounded-lg transition hover:duration-300 hover:bg-fail hover:text-white"
                >
                  Cancel Subscription
                </button>
              </>
            ) : (
              <a href="/join">
                <button className="border w-1/2 pt-2 pb-1 px-2 mt-2 text-left rounded-lg transition hover:duration-300 hover:bg-white hover:text-black">
                  Subscribe
                </button>
              </a>
            )}
          </div>
          <div className="w-full flex text-center place-items-center mt-4">
            {userInfo && userInfo.oneKClub ? (
              <img src={OneKClubLogo} alt="1kclub" className="w-full mt-10" />
            ) : (
              <img
                src={IWClogo}
                alt="iwc"
                className="w-full object-contain grayscale opacity-15 invert rounded-lg"
              />
            )}
          </div>
        </div>
      </div>

      <button
        className="md:w-36 h-9 w-96 nav-links md:static text-magenta text-left flex p-3 font-bcnsb items-center border hover:border-mgreen my-5
          bg-white rounded-lg"
      >
        <a href="/profile/edit">
          <div className="w-full grid grid-cols-2 items-center">
            <div className="self-center">
              <p className="mt-1 text-sm">EDIT</p>
            </div>
            <div className="flex items-center justify-end text-sm">
              <ion-icon name="arrow-forward-circle-outline"></ion-icon>
            </div>
          </div>
        </a>
      </button>
      {isLoading && <p>Loading...</p>}
      {userInfo && userInfo.isAdmin && (
        <>
          <button
            onClick={exportContactsHandler}
            className="md:w-36 h-9 w-96 nav-links md:static text-magenta hover:text-black text-left flex p-3 font-bcnsb items-center border hover:bg-mgreen my-5
         bg-white rounded-lg"
          >
            <div className="w-full grid grid-cols-2 items-center">
              <div className="self-center">
                <p className="mt-1 text-sm">CONTACTS</p>
              </div>
              <div className="flex items-center justify-end text-sm">
                <ion-icon name="arrow-down-circle-outline"></ion-icon>
              </div>
            </div>
          </button>
        </>
      )}

      <button
        onClick={logoutHandler}
        className="md:w-36 h-9 w-96 nav-links md:static text-magenta text-left flex p-3 border hover:border-mgreen font-bcnsb items-center my-5
          bg-white rounded-lg"
      >
        <div className="w-full grid grid-cols-2 items-center">
          <div className="self-center">
            <p className="mt-1 text-sm">LOGOUT</p>
          </div>
          <div className="flex items-center justify-end text-sm">
            <ion-icon name="arrow-forward-circle-outline"></ion-icon>
          </div>
        </div>
      </button>
    </div>
  );
};

export default ProfileScreen;
