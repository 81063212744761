import React, { useState } from "react";
import { useLoginMutation } from "../slices/usersApiSlice";
import { setCredentials } from "../slices/authSlice";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";

const LoginScreen = () => {
  const [error, setError] = useState("");
  const [login, { isLoading }] = useLoginMutation();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { userInfo } = useSelector((state) => state.auth);
  const redirect = "/profile";

  useEffect(() => {
    if (userInfo) {
      navigate(redirect);
    }
  }, [userInfo, redirect, navigate]);

  const submitHandler = async (e) => {
    e.preventDefault();
    try {
      const res = await login({ email, password }).unwrap();
      dispatch(setCredentials({ ...res }));
      toast("Login Successful");
      navigate(redirect);
    } catch (error) {
      toast.error(error.message || "An error occurred");
      setError(error.message);
    }
  };

  return (
    <div className="h-full">
      <div className="grid grid-rows-3 items-center justify-center pb-20 mb-20">
        <h2 className="text-4xl md:text-5xl text-center font-bcneb">Login</h2>
        <form
          onSubmit={submitHandler}
          className="flex flex-col font-bcnsb gap-3"
        >
          <label htmlFor="email">Email</label>
          <input
            type="email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            id="email"
            name="email"
            placeholder="Enter Email"
            className="w-96 font-bcnbook rounded-md p-2 focus:outline-none focus:ring-2 focus:ring-lgreen focus:border-transparent"
          />
          <label htmlFor="password">Password</label>
          <input
            type="password"
            id="password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            name="password"
            placeholder="Enter Password"
            className="w-96 font-bcnbook  rounded-md p-2 focus:outline-none focus:ring-2 focus:ring-lgreen focus:border-transparent"
          />
          <button
            type="submit"
            className="md:w-96 h-9 w-96 nav-links md:static text-white text-left flex p-5 mt-5 font-bcnsb items-center
          bg-[conic-gradient(#03D38B,#10917B,#356C59,#400E32,#A61F69,#C5478C,#8CEBCB,#03D38B)] rounded-lg hover:opacity-50"
          >
            <div className="w-full grid grid-cols-2 items-center">
              <div className="self-center">
                <p className="mt-1 text-sm">LOGIN</p>
              </div>
              <div className="flex items-center justify-end text-sm">
                <ion-icon name="arrow-forward-circle-outline"></ion-icon>
              </div>
            </div>
          </button>
        </form>
        <a
          href="/register"
          className="text-center mt-5 underline text-lg font-bcnsb"
        >
          New User? Register Here
        </a>
      </div>
    </div>
  );
};

export default LoginScreen;
