import React from "react";

const Construction = () => {
  return (
    <div>
      <h1 className="text-5xl font-bcneb place-self-center">
        ! Under Construction
      </h1>
    </div>
  );
};

export default Construction;
