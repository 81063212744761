import { CONTACT_URL } from "../constants";
import { apiSlice } from "./apiSlice";

export const contactsApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getContacts: builder.query({
      query: () => ({
        url: CONTACT_URL,
      }),
    }),
    createContact: builder.mutation({
      query: (data) => ({
        url: CONTACT_URL,
        method: "POST",
        body: data,
      }),
    }),
  }),
});

export const {
  useGetContactsQuery,
  useCreateContactMutation,
  useExportContactsQuery,
} = contactsApiSlice;
