import { REPORT_URL } from "../constants";
import { apiSlice } from "./apiSlice";
import { UPLOAD_URL } from "../constants";

export const reportsApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getReports: builder.query({
      query: () => REPORT_URL,
    }),
    createReport: builder.mutation({
      query: (data) => ({
        url: REPORT_URL,
        method: "POST",
        body: data,
      }),
    }),
    deleteReport: builder.mutation({
      query: ({ id }) => ({
        url: REPORT_URL + `/${id}`,
        method: "DELETE",
      }),
    }),
    uploadReport: builder.mutation({
      query: (file) => ({
        url: UPLOAD_URL + "/report",
        method: "POST",
        body: file,
      }),
    }),
    getReportById: builder.query({
      query: ({ id }) => REPORT_URL + `/${id}`,
    }),
  }),
});

export const {
  useGetReportsQuery,
  useCreateReportMutation,
  useDeleteReportMutation,
  useUploadReportMutation,
  useGetReportByIdQuery,
} = reportsApiSlice;
