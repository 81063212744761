import React from "react";
import { Carousel, IconButton } from "@material-tailwind/react";
import SampleCarousel from "./mini/SampleCarousel";
import { useSelector } from "react-redux";
import { useCreateCheckoutSessionMutation } from "../slices/paymentsApiSlice";
import { useUpdateUserToSubscribeMutation } from "../slices/usersApiSlice";
import "./Components.css";
import { useEffect } from "react";
import { toast } from "react-toastify";
import { loadStripe } from "@stripe/stripe-js";

const SubscriptionCTA = () => {
  const { userInfo } = useSelector((state) => state.auth);
  const [createCheckoutSession] = useCreateCheckoutSessionMutation();

  const priceList = [
    process.env.REACT_APP_PRICE_ID_1,
    process.env.REACT_APP_PRICE_ID_2,
    process.env.REACT_APP_PRICE_ID_3,
  ];

  const checkoutHandler = async (product) => {
    const data = {
      priceId: priceList[product],
      customer_email: userInfo.email,
    };
    try {
      const res = await createCheckoutSession(data).unwrap();
      const stripe = await loadStripe(process.env.REACT_APP_STRIPE_PUBLIC_KEY);
      const result = await stripe.redirectToCheckout({
        sessionId: res.id,
      });

      if (result.error) {
        toast.error(result.error.message);
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    // Check to see if this is a redirect back from Checkout
    const query = new URLSearchParams(window.location.search);

    if (query.get("success")) {
      toast.success("Order placed! You will receive an email confirmation.");
    }

    if (query.get("canceled")) {
      toast.error(
        "Order canceled -- continue to shop around and checkout when you're ready."
      );
    }
  }, []);

  return (
    <div className="w-full h-full p-5 items-center mb-5">
      <div className="grid grid-rows-1 md:gap-5 gap-3 items-center text-center candle-bg md:bg-opacity-10 bg-opacity-25 bg-cover mb-10">
        <div className="md:w-1/2 w-full justify-self-center items-center">
          <h2 className="md:text-6xl text-3xl text-center font-bcneb">
            {" "}
            GET OUR <span className="text-mgreen">MONTHLY</span> TRADE REPORT
            AND INSIGHTS!
          </h2>
        </div>
        <div className="max-w-2xl mx-auto">
          <SampleCarousel />
        </div>

        <div className="grid md:grid-cols-3 md:grid-rows-1 gap-5 grid-rows-3 text-black items-center place-self-center mt-5 md:pb-20 pb-5">
          {userInfo ? (
            <>
              <button
                type="submit"
                onClick={() => checkoutHandler(0)}
                className="md:w-72 w-full md:h-96 h-60 text-center p-3 flex font-bcnsb items-center
          bg-[conic-gradient(#03D38B,#10917B,#356C59,#400E32,#A61F69,#C5478C,#8CEBCB,#03D38B)] rounded-lg drop-shadow-lg hover:duration-300 hover:opacity-50"
              >
                <div className="w-full h-full rounded-lg flex flex-col bg-white justify-center">
                  <div className="self-center flex flex-col content-end justify-center row-span-2">
                    <p className="md:block hidden text-xl p-2">SUBSCRIBE</p>
                    <h3 className="md:text-3xl text-xl font-bcneb">WEEKLY</h3>
                    <h3 className="md:text-6xl text-3xl md:mt-9 mt-2 font-bcneb mb-4">
                      15<span className="md:text-2xl text-lg">SGD</span>
                    </h3>
                    <hr className="mx-4"></hr>
                    <p className="text-sm md:text-md text-left md:self-center font-bcnbook md:mt-10 py-1 px-3">
                      Gain access to our weekly trade reports and insights!
                    </p>
                    <p className="text-sm md:text-md text-left md:self-center font-bcnbook py-1 px-3">
                      Join our private Discord channel to access community
                      resources!
                    </p>
                  </div>
                </div>
              </button>
              <button
                value={1}
                type="submit"
                onClick={() => checkoutHandler(1)}
                className="md:w-72 w-full md:h-96 h-60 text-center p-3 flex font-bcnsb items-center
          bg-[conic-gradient(#03D38B,#10917B,#356C59,#400E32,#A61F69,#C5478C,#8CEBCB,#03D38B)] rounded-lg drop-shadow-lg hover:duration-300 hover:opacity-50"
              >
                <div className="w-full h-full rounded-lg flex flex-col bg-white justify-center">
                  <div className="self-center flex flex-col content-end justify-center row-span-2">
                    <p className="md:block hidden text-xl p-2">SUBSCRIBE</p>
                    <h3 className="md:text-3xl text-xl font-bcneb">MONTHLY</h3>
                    <p className="text-sm md:text-md text-center md:self-center font-bcnbook py-1 px-3">
                      Save 10SGD per month!
                    </p>
                    <h3 className="md:text-6xl text-3xl mt-2 font-bcneb mb-4">
                      50<span className="md:text-2xl text-lg">SGD</span>
                    </h3>
                    <hr className="mx-4"></hr>
                    <p className="text-sm md:text-md text-left md:self-center font-bcnbook md:mt-10 py-1 px-3">
                      Gain access to our weekly trade reports and insights!
                    </p>
                    <p className="text-sm md:text-md text-left md:self-center font-bcnbook py-1 px-3">
                      Join our private Discord channel to access community
                      resources!
                    </p>
                  </div>
                </div>
              </button>
              <button
                value={2}
                type="submit"
                onClick={() => checkoutHandler(2)}
                className="md:w-72 w-full md:h-96 h-60 text-center p-3 flex font-bcnsb items-center
          bg-[conic-gradient(#03D38B,#10917B,#356C59,#400E32,#A61F69,#C5478C,#8CEBCB,#03D38B)] rounded-lg drop-shadow-lg hover:duration-300 hover:opacity-50"
              >
                <div className="w-full h-full rounded-lg flex flex-col bg-white justify-center">
                  <div className="self-center flex flex-col content-end justify-center row-span-2">
                    <p className="md:block hidden text-xl p-2">SUBSCRIBE</p>
                    <h3 className="md:text-3xl text-xl font-bcneb">YEARLY</h3>
                    <p className="text-sm md:text-md text-center md:self-center font-bcnbook py-1 px-3">
                      Save 280SGD per year!
                    </p>
                    <h3 className="md:text-6xl text-3xl mt-2 font-bcneb mb-4">
                      500<span className="md:text-2xl text-lg">SGD</span>
                    </h3>
                    <hr className="mx-4"></hr>
                    <p className="text-sm md:text-md text-left md:self-center font-bcnbook md:mt-10 py-1 px-3">
                      Gain access to our weekly trade reports and insights!
                    </p>
                    <p className="text-sm md:text-md text-left md:self-center font-bcnbook py-1 px-3">
                      Join our private Discord channel to access community
                      resources!
                    </p>
                  </div>
                </div>
              </button>
            </>
          ) : (
            <a href="/login" className="col-span-3">
              <button
                className=" w-72 h-16 text-white text-left flex p-5 font-bcnsb items-center
            bg-[conic-gradient(#03D38B,#10917B,#356C59,#400E32,#A61F69,#C5478C,#8CEBCB,#03D38B)] rounded-lg hover:opacity-35"
              >
                <div className="w-full grid grid-cols-2 items-center">
                  <div className="self-center">
                    <p className="mt-1">SUBSCRIBE</p>
                  </div>
                  <div className="flex items-center justify-end text-3xl">
                    <ion-icon name="arrow-forward-circle-outline"></ion-icon>
                  </div>
                </div>
              </button>
            </a>
          )}
        </div>
      </div>
    </div>
  );
};

export default SubscriptionCTA;
