import React from "react";

function PrivacyPolicy() {
  return (
    <div className="max-w-4xl mx-auto px-4 py-8 font-bcnsb">
      <h1 className="text-3xl font-bold text-center mb-6">Privacy Policy</h1>
      <p className="mb-4">
        <strong>Effective Date:</strong> 12 May 2024
      </p>

      <h2 className="text-2xl font-semibold mt-6 mb-2">1. Introduction</h2>
      <p className="mb-4">
        Welcome to Invest With Certainty! We are committed to protecting your
        privacy and ensuring that your personal information is collected and
        used properly, transparently, and securely. Our Privacy Policy describes
        the practices concerning the data we collect from
        www.investwithcertainty.com (“the Website”), which links you to our
        masterclasses and provides you with the ability to sign up for more
        detailed services.
      </p>

      <h2 className="text-2xl font-semibold mt-6 mb-2">
        2. Information Collection
      </h2>
      <h3 className="text-xl font-semibold mt-4 mb-2">
        2.1 Personal Information:
      </h3>
      <p className="mb-4">We collect personal information when you:</p>
      <ul className="list-disc list-inside mb-4">
        <li>Register on our site.</li>
        <li>Purchase masterclasses or other services.</li>
        <li>Contact us directly via email, phone, or live chat.</li>
      </ul>
      <p>
        This information may include but is not limited to your name, email
        address, postal address, phone number, and payment information.
      </p>
      <h3 className="text-xl font-semibold mt-4 mb-2">
        2.2 Non-Personal Information:
      </h3>
      <p className="mb-4">
        We also collect non-personal information which does not identify you
        personally, such as browsing history, preferences expressed, and
        interaction with the website, via cookies and other tracking
        technologies.
      </p>

      <h2 className="text-2xl font-semibold mt-6 mb-2">
        3. Use of Information
      </h2>
      <p className="mb-4">Your information is used to:</p>
      <ul className="list-disc list-inside mb-4">
        <li>Process transactions and deliver purchased services.</li>
        <li>
          Communicate with you, respond to inquiries, and provide customer
          support.
        </li>
        <li>
          Send you promotional material and newsletters, only if you have
          opted-in.
        </li>
        <li>Improve website usability and overall customer experience.</li>
        <li>Comply with legal requirements and prevent fraud.</li>
      </ul>

      <h2 className="text-2xl font-semibold mt-6 mb-2">
        4. Information Sharing and Disclosure
      </h2>
      <h3 className="text-xl font-semibold mt-4 mb-2">
        4.1 With Service Providers:
      </h3>
      <p className="mb-4">
        We share your information with service providers under contract who help
        with parts of our business operations such as payment processing, fraud
        prevention, and marketing campaigns.
      </p>
      <h3 className="text-xl font-semibold mt-4 mb-2">
        4.2 Legal Requirements:
      </h3>
      <p className="mb-4">
        We may disclose your information if required to do so by law or in the
        good faith belief that such action is necessary to:
      </p>
      <ul className="list-disc list-inside mb-4">
        <li>
          Conform to the edicts of the law or comply with legal process served
          on us.
        </li>
        <li>Protect and defend our rights or property.</li>
        <li>
          Act under exigent circumstances to protect the personal safety of
          users of the Website, or the public.
        </li>
      </ul>
      <h3 className="text-xl font-semibold mt-4 mb-2">
        4.3 Business Transfers:
      </h3>
      <p className="mb-4">
        In connection with any merger, sale of company assets, or acquisition of
        all or a portion of our business by another company, the information of
        our users will likely be among the transferred assets.
      </p>

      <h2 className="text-2xl font-semibold mt-6 mb-2">5. Data Security</h2>
      <p className="mb-4">
        We take reasonable steps to protect the information we collect from you
        to prevent loss, misuse, unauthorized access, disclosure, alteration,
        and destruction. We implement various physical, administrative, and
        technical safeguards designed to protect your information.
      </p>

      <h2 className="text-2xl font-semibold mt-6 mb-2">6. User Rights</h2>
      <p className="mb-4">You have the right to:</p>
      <ul className="list-disc list-inside mb-4">
        <li>Access the information we hold about you.</li>
        <li>Request that we correct any inaccuracies in your information.</li>
        <li>Request the deletion of information concerning you.</li>
        <li>Withdraw any consent you previously provided to us.</li>
        <li>Lodge a complaint with a supervisory authority in your country.</li>
      </ul>

      <h2 className="text-2xl font-semibold mt-6 mb-2">
        7. Cookies and Tracking Technologies
      </h2>
      <p className="mb-4">
        We use cookies to track the activity on our service and hold certain
        information. Cookies are files with a small amount of data which may
        include an anonymous unique identifier. You can instruct your browser to
        refuse all cookies or indicate when a cookie is being sent.
      </p>

      <h2 className="text-2xl font-semibold mt-6 mb-2">
        8. Changes to Privacy Policy
      </h2>
      <p className="mb-4">
        We reserve the right to modify this privacy policy at any time, so
        please review it frequently. Changes and clarifications will take effect
        immediately upon their posting on the website. If we make material
        changes to this policy, we will notify you here that it has been
        updated.
      </p>

      <h2 className="text-2xl font-semibold mt-6 mb-2">
        9. Contact Information
      </h2>
      <p>
        If you have any questions about this Privacy Policy, please contact us
        by email at [Insert Email], by phone at [Insert Phone Number], or by
        mail at [Insert Mailing Address].
      </p>
    </div>
  );
}

export default PrivacyPolicy;
