import React from "react";
import PhoneVideoMarquee from "./mini/PhoneVideoMarquee";
import VideoMarquee from "./mini/VideoMarquee";
import test1 from "../images/testimonial/testimonialImage1.png";
import test2 from "../images/testimonial/testimonialImage2.png";
import test3 from "../images/testimonial/testimonialImage3.png";
import test4 from "../images/testimonial/testimonialImage4.png";
import test5 from "../images/testimonial/testimonialImage5.png";
import test6 from "../images/testimonial/testimonialImage6.png";
import TradeMarquee from "./mini/TradeMarquee";
import GoogleReviews from "./mini/GoogleReviews";
import PhoneTradeMarquee from "./mini/PhoneTradeMarquee";

const Testimonials = () => {
  return (
    <div className="w-full h-full pt-5 mb-10 text-center">
      <div className="">
        <h2 className="md:text-5xl text-2xl mt-5 mb-5 font-bcneb ">
          HEAR WHAT OUR ALUMNI HAVE TO SAY
        </h2>
      </div>
      <div className="w-full grid md:grid-rows-3">
        <div className="md:block hidden">
          <VideoMarquee />
        </div>
        <div className="w-full ">
          <GoogleReviews />
        </div>
        <div className=" hidden md:grid md:items-center h-52">
          <TradeMarquee />
        </div>
        <div className="md:hidden block p-2">
          <PhoneTradeMarquee />
        </div>
      </div>
    </div>
  );
};

export default Testimonials;
