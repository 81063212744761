import React from "react";
import graphs from "../images/graphs.png";
import "../components/Components.css";
import ReactWhatsapp from "react-whatsapp";
import whatsappLogo from "../images/whatsapp.png";
import OneKCTA from "../components/OneKCTA";

const ContactScreen = () => {
  return (
    <>
      <div className="h-full">
        <section
          id="contact-hero"
          className="grid grid-rows-2 mt-20 justify-items-center "
        >
          <section className="grid md:grid-rows-4 grid-cols-1 grid-rows-1 text-center md:w-1/2 w-full mt-5 place-items-center place-self-center">
            <div>
              <h2 className=" font-bcneb text-5xl place-self-center">
                CONTACT US!
              </h2>
            </div>
            <div className="flex justify-self-center">
              <p className=" flex justify-self-center p-2 font-bcnsb">
                IWC CAN HELP YOU IN YOUR TRADING JOURNEY!
              </p>
            </div>
            <button className="w-72 h-16 text-white text-left place-self-center items-center flex p-4 font-bcnsb bg-[conic-gradient(#03D38B,#10917B,#356C59,#400E32,#A61F69,#C5478C,#8CEBCB,#03D38B)] rounded-lg">
              <ReactWhatsapp
                className="w-full grid grid-cols-5 items-center"
                number="+65 8784 3611"
                message="Hi! I would like to know more about IWC and 1K Club."
              >
                <img
                  className="object-contain col-span-1 w-9 items-start"
                  src={whatsappLogo}
                ></img>
                <h4 className="text- w-full col-span-4 flex items-center justify-self-start">
                  {" "}
                  SEND US A MESSAGE
                </h4>
              </ReactWhatsapp>
            </button>
          </section>
          <section>
            <img src={graphs} className="w-full h-full"></img>
          </section>
        </section>
        <section
          id="details"
          className="mb-20 bg-banner md:bg-repeat bg-no-repeat bg-mgreen bg-contain p-10 bg-center"
        >
          <div className="grid grid-rows-5">
            <div className="">
              <h3 className="md:text-4xl text-2xl place-self-center text-center font-bcneb">
                REACH US AT
              </h3>
            </div>
            <div className="text-center mb-5">
              <h3 className="md:text-3xl text-2xl font-bcnsb">ADDRESS:</h3>
              <p className="text-2xl font-bcnbook">High Street Centre </p>
              <p className="text-xl font-bcnbook">1 North Bridge Rd</p>
              <p className="text-xl font-bcnbook">Singapore 179094</p>
            </div>

            <div className="text-center">
              <h3 className="text-2xl font-bcnsb">EMAIL:</h3>
              <p className="text-xl font-bcnbook">hello@pride5.org</p>
            </div>
          </div>
        </section>
        <OneKCTA />
      </div>
    </>
  );
};

export default ContactScreen;
