import React from "react";
import Testimonials from "../components/Testimonials";
import OneKBanner from "../components/OneKBanner";
import Benefits from "../components/Benefits";
import InvestorInfo from "../components/InvestorInfo";
import OneKCTA from "../components/OneKCTA";
import "../components/Components.css";
import GoogleReviews from "../components/mini/GoogleReviews";

const OneKScreen = () => {
  return (
    <>
      <div className="w-full h-full">
        <OneKBanner />
        <Benefits />
        <div className="w-full mt-20">
          <OneKCTA />
          <Testimonials />
        </div>

        <InvestorInfo />
        <OneKCTA />
      </div>
    </>
  );
};

export default OneKScreen;
