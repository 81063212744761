import React from "react";
import AdVideoMarquee from "../components/mini/AdVideoMarquee";
import OneKLogo from "../images/1KLogo.png";
import { Carousel, IconButton } from "@material-tailwind/react";

const Resources = () => {
  const range = (min, max) =>
    Array.from({ length: max - min + 1 }, (_, i) => min + i);

  return (
    <div className="w-full h-full mt-20">
      <section className="items-center justify-center mt-5">
        <div className="items-center justify-center">
          <h3 className="md:text-5xl text-4xl p-5 text-center font-bcneb ">
            {" "}
            CHECK OUT OUR SUCCESS STORIES!{" "}
          </h3>
        </div>
        <AdVideoMarquee />
      </section>
    </div>
  );
};

export default Resources;
