import { USERS_URL } from "../constants";
import { apiSlice } from "./apiSlice";

export const usersApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getUsers: builder.query({
      query: () => USERS_URL,
    }),
    login: builder.mutation({
      query: ({ email, password }) => ({
        url: USERS_URL + "/login",
        method: "POST",
        body: { email, password },
      }),
    }),
    register: builder.mutation({
      query: (data) => ({
        url: USERS_URL + "/register",
        method: "POST",
        body: data,
      }),
    }),
    logout: builder.mutation({
      query: () => ({
        url: USERS_URL + "/logout",
        method: "GET",
      }),
    }),
    profile: builder.query({
      query: () => USERS_URL + "/profile",
    }),
    updateUserToSubscribe: builder.mutation({
      query: () => ({
        url: USERS_URL + "/subscribe",
        method: "PUT",
      }),
    }),
    updateUser: builder.mutation({
      query: (data) => ({
        url: USERS_URL + "/profile",
        method: "PUT",
        body: data,
      }),
    }),
  }),
});

export const {
  useGetUsersQuery,
  useLoginMutation,
  useRegisterMutation,
  useLogoutMutation,
  useProfileQuery,
  useUpdateUserToSubscribeMutation,
  useUpdateUserMutation,
} = usersApiSlice;
