import React, { useRef, useState } from "react";
import Resources from "../components/Resources";
import emailjs from "@emailjs/browser";
import { toast } from "react-toastify";
import SubscriptionCTA from "../components/SubscriptionCTA";
import { useCreateContactMutation } from "../slices/contactsApiSlice";

const JoinUsScreen = () => {
  const [createContact] = useCreateContactMutation();
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const form = useRef();

  const sendEmail = async (e) => {
    e.preventDefault();

    emailjs
      .sendForm(
        process.env.REACT_APP_EMAILJS_SERVICE_KEY,
        "template_icqzz3n",
        form.current,
        {
          publicKey: process.env.REACT_APP_EMAILJS_PUBLIC_KEY,
        }
      )
      .then(
        () => {
          toast("Email Sent Successfully!");
        },
        (error) => {
          toast("Error sending email!");
          console.log(error);
        }
      );

    const data = {
      name,
      email,
      phone,
    };
    const created = await createContact(data);
    form.current.reset();
  };

  return (
    <div>
      <section className="w-full">
        <Resources />
      </section>
      <section>
        <SubscriptionCTA />
      </section>
      <section
        id="join-hero"
        className="w-full grid grid-rows-2 h-full justify-items-center bg-waves bg-no-repeat bg-cover p-10"
      >
        <div className="md:w-1/2 w-full bg-white bg-opacity-40 rounded-lg">
          <h2 className="md:text-5xl text-2xl text-center font-bcneb text-black mb-5 ">
            JOIN OUR MASTERCLASS!
          </h2>
          <p className="text-xl text-center font-bcnsb mb-5">
            Join our bi-weekly trade meetings and start your trading journey!
          </p>
          <form ref={form} className="p-5" onSubmit={(e) => sendEmail(e)}>
            <div className="">
              <label className="text-md md:text-xl mb-5 font-bcnsb">Name</label>
              <input
                type="text"
                name="name"
                onChange={(e) => setName(e.target.value)}
                className="w-full h-10 p-2 mb-5 rounded-lg"
                placeholder="Enter your name"
              />
            </div>
            <div className="">
              <label className="text-md md:text-xl mb-5 font-bcnsb ">
                Email
              </label>
              <input
                type="email"
                name="email"
                onChange={(e) => setEmail(e.target.value)}
                className="w-full h-10 p-2 mb-5 rounded-lg"
                placeholder="Enter your email"
              />
            </div>
            <div className="">
              <label className="text-md md:text-xl mb-2 font-bcnsb  ">
                Phone Number
              </label>
              <input
                type="phone"
                name="phone"
                onChange={(e) => setPhone(e.target.value)}
                className="w-full h-10 p-2 mb-5 rounded-lg"
                placeholder="Enter your phone number"
              />
            </div>
            <button
              type="submit"
              value="Send"
              className="w-full h-16 text-white text-left place-self-center items-center text-xl flex p-4 font-bcnsb bg-[conic-gradient(#03D38B,#10917B,#356C59,#400E32,#A61F69,#C5478C,#8CEBCB,#03D38B)] rounded-lg"
            >
              Receive Email
            </button>
          </form>
        </div>
      </section>
    </div>
  );
};

export default JoinUsScreen;
