import React from "react";
import investorImage from "../images/traderpicture.png";
import "./Components.css";

const InvestorInfoTwo = () => {
  return (
    <div id="investor-info" className="w-full h-full mt-5 ">
      <div className="md:text-5xl text-3xl w-full text-black font-bcneb grid grid-cols-1 place-items-center">
        <div className="m-2 flex justify-center">
          <h2 className=" md:w-1/2 text-center justify-self-center">
            MADE BY SELF TAUGHT TRADERS FOR SELF LEARNING TRADERS
          </h2>
        </div>
        <div className="m-2 place-items-center font-bcnsb grid grid-rows-1 p-2 text-center text-2xl md:w-1/2">
          <p className="">
            With a combined 10+ years of experience of “Walk The Talk” trading
            and investing, they are specialists in helping their students
            achieve breakthrough portfolio growth with proven wealth building
            strategies.
          </p>
        </div>
        <div className="candle-dark-bg bg-contain w-full flex justify-center">
          <img src={investorImage} alt="investors" className="w-3/4" />
        </div>
      </div>
    </div>
  );
};

export default InvestorInfoTwo;
