import React from "react";
import InvestorInfoTwo from "../components/InvestorInfoTwo";
import OneKCTA from "../components/OneKCTA";

const AboutScreen = () => {
  return (
    <>
      <div className="w-full h-full">
        <section id="about-hero" className="grid h-full w-full mt-20">
          <InvestorInfoTwo />
        </section>
        <section
          id="about"
          className="mb-5 w-full h-full bg-banner bg-cover bg-no-repeat"
        >
          <div className="w-full p-20 ">
            <h3 className="font-bcneb text-5xl text-center pt-10 mb-5">
              WHO WE ARE
            </h3>

            <p className="text-left font-bcnsb text-2xl mb-5">
              With so much uncertainty and noise in today’s current market,
              Founders Sureesh and Isaac have made it their mission to help
              people to Invest With Certainty.
            </p>
            <p className="text-left font-bcnsb text-2xl mb-5">
              With a combined 10+ years of experience of “Walk The Talk” trading
              and investing, they are specialists in helping their students
              achieve breakthrough portfolio growth with proven wealth building
              strategies.
            </p>
            <p className="text-left font-bcnsb text-2xl mb-5">
              Combined with a community of over 200 strong built by the 2
              traders, join the movement, utilise their proven wealth
              strategies, and you too, can Invest With Certainty.
            </p>
          </div>
        </section>
        <section id="goals" className="">
          <div className="grid grid-cols-2 gap-2 p-10">
            <div className="border border-lgreen rounded-lg p-10 shadow-md">
              <h3 className="text-3xl font-bcneb text-left">Vision</h3>
              <p className="text-left font-bcnsb md:text-3xl text-md mt-5">
                To empower Our Students To Make $1K A Day.
              </p>
            </div>
            <div className="border border-lgreen rounded-lg p-10 shadow-md">
              <h3 className="text-3xl font-bcneb text-left">Mission</h3>
              <p className="text-left font-bcnsb md:text-3xl text-md mt-5">
                To Be Singapore’s 1st Full Time Options Trading Academy.
              </p>
            </div>
          </div>
        </section>
        <section>
          <OneKCTA />
        </section>
      </div>
    </>
  );
};

export default AboutScreen;
