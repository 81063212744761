import React from "react";
import iwcLogo from "../images/iwc-logo.png";
import { useSelector } from "react-redux";
import { useLogoutMutation } from "../slices/usersApiSlice";
import { logout } from "../slices/authSlice";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";

const Header = () => {
  const { userInfo } = useSelector((state) => state.auth);
  const [userLogout] = useLogoutMutation();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const logoutHandler = async () => {
    try {
      await userLogout().unwrap();
      dispatch(logout());
      navigate("/login");
    } catch (err) {
      toast.error(err.message || "An error occurred");
      console.log(err);
    }
  };

  const onToggleMenu = (e) => {
    e.target.name = e.target.name === "menu" ? "close" : "menu";
    const navLinks = document.querySelector(".nav-links");
    navLinks.classList.toggle("hidden");
  };

  return (
    <header className="bg-white shadow">
      <nav className="flex justify-between items-center w-[92%] mx-auto p-5">
        <div className="flex items-center">
          <a href="/" className="flex items-center">
            <img src={iwcLogo} alt="iwclogo" className="md:w-16 w-9"></img>
            <h2 className="font-bcneb md:text-2xl text-lg text-black m-2">
              {" "}
              Invest With Certainty
            </h2>
          </a>
        </div>
        <div className="nav-links md:static absolute bg-white md:min-h-fit min-h-[30vh] md:shadow-none shadow left-0 top-[9%] md:w-3/4 w-full md:flex items-center px-5 hidden pt-2">
          <ul className="flex text-center md:flex-row w-full flex-col md:items-center md:gap-[4vw] gap-8 font-bcnsb md:text-sm text-3xl mb-5">
            <li className="flex hover:bg-mgreen hover:text-white hover:rounded-lg w-full mx-auto px-2 pt-2">
              <a href="/" className="w-full">
                Home
              </a>
            </li>
            {userInfo && userInfo.isSubscribed ? (
              <li className="flex hover:bg-mgreen hover:text-white hover:rounded-lg w-full mx-auto px-2 pt-2">
                <a href="/reports" className="w-full">
                  Member's Section
                </a>
              </li>
            ) : (
              <></>
            )}
            <li className="flex hover:bg-mgreen hover:text-white hover:rounded-lg w-full px-2 pt-2">
              <a href="/1kclub" className="w-full">
                $1K A Day Club
              </a>
            </li>

            <li className="flex hover:bg-mgreen hover:text-white hover:rounded-lg w-full mx-auto px-2 pt-2">
              <a href="/contact" className="w-full">
                Contact
              </a>
            </li>
            <li className="flex hover:bg-mgreen hover:text-white hover:rounded-lg w-full mx-auto px-2 pt-2">
              <a href="/about" className="w-full">
                About Us
              </a>
            </li>
            {userInfo && userInfo.isSubscribed ? (
              <></>
            ) : (
              <li className="flex hover:bg-mgreen hover:text-white hover:rounded-lg w-full mx-auto px-2 pt-2">
                <a href="/join" className="w-full">
                  Join Us
                </a>
              </li>
            )}

            <li>
              {userInfo ? (
                <>
                  <a href="/profile">
                    <button
                      className="md:w-36 h-9 w-96 nav-links md:static text-white text-left flex p-3 font-bcnsb items-center
        bg-[conic-gradient(#03D38B,#10917B,#356C59,#400E32,#A61F69,#C5478C,#8CEBCB,#03D38B)] hover:opacity-50 rounded-lg"
                    >
                      <div className="w-full grid grid-cols-2 items-center">
                        <div className="self-center">
                          <p className="mt-1 text-sm">Profile</p>
                        </div>
                      </div>
                    </button>
                  </a>
                  <button
                    onClick={logoutHandler}
                    className="md:hidden h-9 w-96 nav-links md:static mt-5 text-magenta text-center flex p-3 font-bcnsb items-center justify-center rounded-lg"
                  >
                    <p className="mt-1 text-sm">Logout</p>
                  </button>
                </>
              ) : (
                <a href="/login">
                  <button
                    className="md:w-36 h-9 w-96 nav-links md:static text-white text-left flex p-3 font-bcnsb items-center
          bg-[conic-gradient(#03D38B,#10917B,#356C59,#400E32,#A61F69,#C5478C,#8CEBCB,#03D38B)] hover:opacity-50 rounded-lg"
                  >
                    <div className="w-full grid grid-cols-2 items-center">
                      <div className="self-center">
                        <p className="mt-1 text-sm">LOGIN</p>
                      </div>
                      <div className="flex items-center justify-end text-sm">
                        <ion-icon name="arrow-forward-circle-outline"></ion-icon>
                      </div>
                    </div>
                  </button>
                </a>
              )}
            </li>
          </ul>
        </div>

        <div className="flex items-center gap-3">
          <button
            className="flex items-center justify-center md:hidden animate-bounce"
            onClick={(e) => onToggleMenu(e)}
          >
            <ion-icon
              name="menu"
              className="text-3xl cursor-pointer"
            ></ion-icon>
          </button>
        </div>
      </nav>
    </header>
  );
};

export default Header;
