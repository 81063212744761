import React, { useState, useEffect } from "react";
import axios from "axios";
import "./MiniComponents.css";

function GoogleReviews() {
  const [reviews, setReviews] = useState([]);
  const apiKey = process.env.REACT_APP_GOOGLE_PLACES_API_KEY;
  const placeId = process.env.REACT_APP_GOOGLE_PLACES_ID; // Replace with actual Place ID

  useEffect(() => {
    const fetchReviews = async () => {
      try {
        const response = await axios.get(
          `https://places.googleapis.com/v1/places/${placeId}?fields=reviews&key=${apiKey}`
        );
        let newReviews = [];
        let i = 0;
        for (const review of response.data.reviews) {
          newReviews.push({
            author_name: review.authorAttribution.displayName,
            author_image: review.authorAttribution.photoUri,
            review_uri: review.authorAttribution.uri,
            rating: review.rating,
            text: review.text.text,
            time: review.publishTime,
          });
          i++;
          if (i === 6) {
            break;
          }
        }
        setReviews(newReviews);
      } catch (error) {
        console.error("Error fetching reviews:", error);
      }
    };

    fetchReviews();
  }, []);

  return (
    <div>
      {reviews?.length > 0 ? (
        <div className="w-full grid md:grid-rows-2 md:grid-cols-3 grid-rows-5 grid-cols-1 items-start font-bcnsb text-left">
          {reviews.map((review, index) => (
            <div className="w-full p-2">
              <div className="border border-mgreen rounded-lg shadow">
                <div className="flex items-center p-2">
                  <div className="ml-2 mt-5 items-center">
                    <a
                      href={review.review_uri}
                      target="_blank"
                      rel="noreferrer"
                      className="text-xl"
                    >
                      {review.author_name}
                    </a>
                    <p className="text-sm">
                      {new Date(review.time).toDateString()}
                    </p>
                  </div>
                </div>
                <div className="p-2">
                  <p id={`review-${index}`} className="">
                    <div
                      className="group flex flex-col gap-2 rounded-lg bg-white p-2 text-black"
                      tabindex="2"
                    >
                      <div className="flex cursor-pointer items-center justify-between">
                        <span className="md:text-2xl text-lg font-bcneb pt-2 text-left">
                          {" "}
                          {review.text.split(".")[0].slice(0, 60)}...
                        </span>
                        <img
                          src="https://upload.wikimedia.org/wikipedia/commons/e/e9/Arrow_bottom.png"
                          className="h-2 w-3 transition-all duration-500 group-focus:-rotate-180"
                        />
                      </div>
                      <div className="invisible h-auto max-h-0 items-start text-left opacity-0 transition-all group-focus:visible group-focus:max-h-screen group-focus:opacity-100 group-focus:duration-1000">
                        {review.text}
                      </div>
                    </div>
                  </p>
                </div>
              </div>
            </div>
          ))}
        </div>
      ) : (
        <p>No reviews available.</p>
      )}
    </div>
  );
}

export default GoogleReviews;
