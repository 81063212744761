import React from "react";
import iwclogo from "../images/iwc-logo-large.png";
import Interest from "../images/interest-rate.png";
import Growth from "../images/growth.png";
import Biz from "../images/business-and-finance.png";
import FAQAccordion from "../components/mini/FAQAccordion";
import InvestorInfoTwo from "../components/InvestorInfoTwo";
import TestimonialsTwo from "../components/TestimonialsTwo";

const HomeScreen = () => {
  return (
    <div className="w-full h-full">
      <section id="landing-hero" className=" h-full overflow-hidden">
        <div className="grid md:grid-cols-2">
          <div className="place-self-center  grid md:grid-rows-3 w-3/4 sm:p-2 mdAPP:p-2 lg:p-10 mt-10">
            <img
              src={iwclogo}
              className="w-1/4 justify-self-center self-end mb-5"
            />
            <div>
              <h2 className="md:text-5xl text-3xl font-bcneb md:text-right text-center mb-5">
                Dedicated To Helping You Win In Your Trading
              </h2>
              <p className="md:text-2xl text-lg font-bcnsb md:text-right text-center mb-5">
                The Fulltime Option Trading Academy (FOTA) With
                Behind-The-Scenes Wealth Experts Who Closely Guide Our Community
                To Success.
              </p>
            </div>
            <a href="/join" className="md:justify-self-end justify-self-center">
              <button
                className="w-72 h-16 text-white text-left flex p-5 font-bcnsb content-center md:justify-self-end justify-self-center self-top
          bg-[conic-gradient(#03D38B,#10917B,#356C59,#400E32,#A61F69,#C5478C,#8CEBCB,#03D38B)] hover:opacity-50 rounded-lg md:w-96"
              >
                <div className="w-full grid grid-cols-2 place-self-center">
                  <div className="self-center">
                    <p className="mt-1">JOIN NOW</p>
                  </div>
                  <div className="place-self-end">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      strokeWidth={1.5}
                      stroke="currentColor"
                      className="w-10 h-10"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        d="m12.75 15 3-3m0 0-3-3m3 3h-7.5M21 12a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z"
                      />
                    </svg>
                  </div>
                </div>
              </button>
            </a>
          </div>
          <div className="bg-home bg-right bg-cover bg-no-repeat hidden md:block"></div>
        </div>
      </section>
      <section
        className="grid md:grid-cols-3 md:grid-rows-1 grid-rows-3 p-20 text-center mb-10 font-bcnsb h-auto "
        id="home-benefit"
      >
        <div className="">
          <div className="grid grid-cols-1">
            <img
              src={Growth}
              alt="growth"
              className="w-48 place-self-center"
            ></img>
          </div>
          <div className="mt-5">
            <p className="text-2xl">Fundamental Knowledge</p>
            <p className="text-xl">Money and Wealth building.</p>
          </div>
        </div>
        <div className="">
          <div className="grid grid-cols-1">
            <img
              src={Interest}
              alt="interest"
              className="w-48 place-self-center "
            ></img>
          </div>
          <div className=" place-items-center mt-5">
            <p className="text-2xl">Options Trading</p>
            <p className="text-xl">The real power of strategic investing.</p>
          </div>
        </div>
        <div className="">
          <div className="grid grid-cols-1">
            <img
              src={Biz}
              alt="business"
              className="w-48 place-self-center"
            ></img>
          </div>
          <div className="mt-5">
            <p className="text-2xl">Key Trading Rules</p>
            <p className="text-xl">
              What every trader needs to follow to achieve accelerated portfolio
              growth.
            </p>
          </div>
        </div>
      </section>
      {/* <section id="interactive-testimonial">
        <InteractiveTestimonial />
      </section> */}

      <InvestorInfoTwo />
      <TestimonialsTwo />
      <section id="faq">
        <FAQAccordion />
      </section>
    </div>
  );
};

export default HomeScreen;
