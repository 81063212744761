import React from "react";
import { Carousel } from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import reportPage0 from "../../images/report/report-page-0.png";
import reportPage1 from "../../images/report/report-page-1.png";
import reportPage2 from "../../images/report/report-page-2.png";
import reportPage3 from "../../images/report/report-page-3.png";
import reportPage4 from "../../images/report/report-page-4.png";
import reportPage5 from "../../images/report/report-page-5.png";
import reportPage6 from "../../images/report/report-page-6.png";
import reportPage7 from "../../images/report/report-page-7.png";
import reportPage8 from "../../images/report/report-page-8.png";
import reportPage9 from "../../images/report/report-page-9.png";
import reportPage10 from "../../images/report/report-page-10.png";

const SampleCarousel = () => {
  return (
    <Carousel className="md:w-full w-96">
      <div>
        <img
          src={reportPage0}
          alt="sample trade report"
          className="h-full w-full object-cover"
        />
      </div>
      <div>
        <img
          src={reportPage1}
          alt="sample trade report"
          className="h-full w-full object-cover"
        />
      </div>
      <div>
        <img
          src={reportPage2}
          alt="sample trade report"
          className="h-full w-full object-cover"
        />
      </div>
      <div>
        <img
          src={reportPage3}
          alt="sample trade report"
          className="h-full w-full object-cover"
        />
      </div>
      <div>
        <img
          src={reportPage4}
          alt="sample trade report"
          className="h-full w-full object-cover"
        />
      </div>
      <div>
        <img
          src={reportPage5}
          alt="sample trade report"
          className="h-full w-full object-cover"
        />
      </div>
      <div>
        <img
          src={reportPage6}
          alt="sample trade report"
          className="h-full w-full object-cover"
        />
      </div>
      <div>
        <img
          src={reportPage7}
          alt="sample trade report"
          className="h-full w-full object-cover"
        />
      </div>
      <div>
        <img
          src={reportPage8}
          alt="sample trade report"
          className="h-full w-full object-cover"
        />
      </div>
      <div>
        <img
          src={reportPage9}
          alt="sample trade report"
          className="h-full w-full object-cover"
        />
      </div>
      <div>
        <img
          src={reportPage10}
          alt="sample trade report"
          className="h-full w-full object-cover"
        />
      </div>
    </Carousel>
  );
};

export default SampleCarousel;
