import React from "react";
import ReactDOM from "react-dom/client";
import {
  createBrowserRouter,
  createRoutesFromElements,
  Route,
  RouterProvider,
} from "react-router-dom";
import { Provider } from "react-redux";
import store from "./store";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import OneKScreen from "./screens/OneKScreen";
import ContactScreen from "./screens/ContactScreen";
import HomeScreen from "./screens/HomeScreen";
import Construction from "./screens/Construction";
import AboutScreen from "./screens/AboutScreen";
import JoinUsScreen from "./screens/JoinUsScreen";
import PrivacyPolicy from "./screens/PrivacyPolicy";
import Terms from "./screens/Terms";
import PrivateRoute from "./components/PrivateRoute";
import SubscribedRoute from "./components/SubscribedRoute";
import LoginScreen from "./screens/LoginScreen";
import RegistrationScreen from "./screens/RegistrationScreen";
import ProfileScreen from "./screens/ProfileScreen";
import AdminRoute from "./components/AdminRoute";
import ProfileEdit from "./screens/ProfileEdit";
import ReportsList from "./screens/ReportsList";
import ReportScreen from "./screens/ReportScreen";
import PaymentFailed from "./screens/PaymentFailed";
import PaymentSuccess from "./screens/PaymentSuccess";
import { disableReactDevTools } from "@fvilers/disable-react-devtools";

if (process.env.NODE_ENV === "production") {
  disableReactDevTools();
}

const router = createBrowserRouter(
  createRoutesFromElements(
    <Route path="/" element={<App />}>
      <Route index={true} element={<HomeScreen />} />
      <Route path="/1kclub" element={<OneKScreen />} />
      <Route path="/contact" element={<ContactScreen />} />
      <Route path="/resources" element={<Construction />} />
      <Route path="/about" element={<AboutScreen />} />
      <Route path="/join" element={<JoinUsScreen />} />
      <Route path="/privacy" element={<PrivacyPolicy />} />
      <Route path="/terms" element={<Terms />} />
      <Route path="/login" element={<LoginScreen />} />
      <Route path="/register" element={<RegistrationScreen />} />

      <Route path="/" element={<PrivateRoute />}>
        <Route path="/profile" element={<ProfileScreen />} />
        <Route path="/profile/edit" element={<ProfileEdit />} />
        <Route path="/subscribe" element={<Construction />} />
        <Route path="/payment-failed" element={<PaymentFailed />} />
        <Route path="/payment-success" element={<PaymentSuccess />} />
      </Route>

      <Route path="/" element={<SubscribedRoute />}>
        <Route path="/reports" element={<ReportsList />} />
        <Route path="/reports/:id" element={<ReportScreen />} />
      </Route>

      <Route path="/" element={<AdminRoute />}>
        <Route path="/admin" element={<Construction />} />
      </Route>
    </Route>
  )
);

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <Provider store={store}>
      <RouterProvider router={router} />
    </Provider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
