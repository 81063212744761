import React, { useEffect, useState } from "react";
import testImage from "../../images/trade-shadow.png";
import "./MiniComponents.css";
import TradeUp from "../../images/tradeup.png";

const TradeMarquee = () => {
  const [tickerList, setTickerList] = useState([]);

  const getJson = () => {
    return fetch(`${process.env.REACT_APP_TICKER_DATA}`)
      .then((response) => response.json())
      .catch((error) => {
        console.error(error);
      });
  };

  useEffect(() => {
    getJson().then((data) => {
      setTickerList(data);
    });
  }, []);

  const firstHalf = tickerList.slice(0, 8);
  const secondHalf = tickerList.slice(8, 16);

  return (
    <div className="w-full h-96 bg-gradient-to-t flex flex-row items-center from-transparent to-white overflow-x-hidden gap-4 p-2">
      <div className="relative h-full items-center">
        <div className="py-1 h-full animate-marquee items-center whitespace-nowrap grid grid-cols-8 gap-6 pr-5">
          {firstHalf.length > 0 ? (
            firstHalf.map((ticker, index) => (
              <div className="text-black h-2/3 font-bcnbook flex flex-col shadow shadow-mgreen p-3 items-center place-items-center w-full rounded-lg">
                <div
                  key={index}
                  className="text-left h-2/3 p-3 items-center grid grid-rows-4"
                >
                  <p className="ml-2 h-full">
                    Ticker: <span className="font-bcneb"> {ticker.ticker}</span>
                  </p>
                  <p className="ml-2">
                    Type:
                    <span className="font-bcneb"> {ticker.tickertype}</span>
                  </p>
                  <p className="ml-2">
                    Profit:
                    <span className="font-bcneb"> ${ticker.profit}</span>
                  </p>
                  <p className="ml-2">
                    Duration:
                    <span className="font-bcneb"> {ticker.duration}</span>
                  </p>
                  <p className="ml-2">
                    Date:<span className="font-bcneb"> {ticker.date}</span>
                  </p>
                </div>
                <div className="flex flex-row items-center w-full h-full bg-lgreen rounded-lg p-1">
                  <p className="ml-2 justify-start">
                    ROI:<span className="font-bcneb"> {ticker.roi} % </span>
                  </p>
                  <img
                    src={TradeUp}
                    alt="trade green"
                    className="justify-self-end place-self-center w-5 h-5 mx-auto p-1"
                  />
                </div>
              </div>
            ))
          ) : (
            // <div key={index} className="flex items-center">
            <p>Loading...</p>
          )}
        </div>
        <div className="absolute w-full top-0 items-center h-full py-1 animate-marquee2 whitespace-nowrap grid grid-cols-8 gap-5">
          {secondHalf.length > 0 ? (
            secondHalf.map((ticker, index) => (
              <div className="text-black h-2/3 font-bcnbook flex flex-col shadow shadow-mgreen p-3 items-center place-items-center w-full rounded-lg">
                <div
                  key={index}
                  className="text-left h-2/3 p-3 items-center grid grid-rows-4"
                >
                  <p className="ml-2 h-full">
                    Ticker: <span className="font-bcneb"> {ticker.ticker}</span>
                  </p>
                  <p className="ml-2">
                    Type:
                    <span className="font-bcneb"> {ticker.tickertype}</span>
                  </p>
                  <p className="ml-2">
                    Profit:
                    <span className="font-bcneb"> ${ticker.profit}</span>
                  </p>
                  <p className="ml-2">
                    Duration:
                    <span className="font-bcneb"> {ticker.duration}</span>
                  </p>
                  <p className="ml-2">
                    Date:<span className="font-bcneb"> {ticker.date}</span>
                  </p>
                </div>
                <div className="flex flex-row items-center w-full h-full bg-lgreen rounded-lg p-1">
                  <p className="ml-2 justify-start">
                    ROI:<span className="font-bcneb"> {ticker.roi} % </span>
                  </p>
                  <img
                    src={TradeUp}
                    alt="trade green"
                    className="justify-self-end place-self-center w-5 h-5 mx-auto p-1"
                  />
                </div>
              </div>
            ))
          ) : (
            // <div key={index} className="flex items-center">
            <p>Loading...</p>
          )}
        </div>
      </div>
    </div>
  );
};

export default TradeMarquee;
