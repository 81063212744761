import React from "react";

const OneKCTA = () => {
  return (
    <div className="w-full h-full p-5 items-center">
      <div className="grid grid-rows-2 md:gap-5 gap-3 items-center text-center">
        <div className="md:w-1/2 w-full justify-self-center items-center">
          <h2 className="md:text-6xl text-3xl text-center font-bcneb">
            {" "}
            CHECK OUT OUR <span className="text-mgreen">FREE</span> IWC
            MASTERCLASS!
          </h2>
        </div>
        <div className="flex items-center place-self-center">
          <a href="/join">
            <button
              href="/join"
              className=" w-72 h-16 text-white text-left flex p-5 font-bcnsb items-center
          bg-[conic-gradient(#03D38B,#10917B,#356C59,#400E32,#A61F69,#C5478C,#8CEBCB,#03D38B)] hover:opacity-50 rounded-lg"
            >
              <div className="w-full grid grid-cols-2 items-center">
                <div className="self-center">
                  <p className="mt-1">JOIN NOW</p>
                </div>
                <div className="flex items-center justify-end text-3xl">
                  <ion-icon name="arrow-forward-circle-outline"></ion-icon>
                </div>
              </div>
            </button>
          </a>
        </div>
      </div>
    </div>
  );
};

export default OneKCTA;
