import React, { useState } from "react";
import { useSelector } from "react-redux";
import { useUpdateUserMutation } from "../slices/usersApiSlice";
import { setCredentials } from "../slices/authSlice";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";

const ProfileEdit = () => {
  const { userInfo } = useSelector((state) => state.auth);
  const [updateUser, { isLoading }] = useUpdateUserMutation();
  const [email, setEmail] = useState(userInfo.email);
  const [name, setName] = useState(userInfo.name);
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const submitHandler = async (e) => {
    e.preventDefault();
    if (password !== confirmPassword) {
      toast.error("Passwords do not match");
      return;
    } else {
      try {
        const data = { name, email, password };
        const res = await updateUser(data).unwrap();
        dispatch(setCredentials({ ...res }));
        toast("Update Successful");
        navigate("/reports");
      } catch (error) {
        toast.error(error.message || "An error occurred");
        console.log(error);
      }
    }
  };

  return (
    <>
      <div>
        <div className="grid grid-rows-3 items-center justify-center">
          <h2 className="text-4xl md:text-5xl text-center font-bcneb">
            Edit Profile
          </h2>
          <form
            onSubmit={submitHandler}
            className="flex flex-col font-bcnsb gap-3"
          >
            <label htmlFor="name">Name</label>
            <input
              type="text"
              value={name}
              onChange={(e) => setName(e.target.value)}
              id="name"
              name="name"
              placeholder="Enter Name"
              className="w-96 font-bcnbook rounded-md p-2 focus:outline-none focus:ring-2 focus:ring-lgreen focus:border-transparent"
            />
            <label htmlFor="email">Email</label>
            <input
              type="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              id="email"
              name="email"
              placeholder="Enter Email"
              className="w-96 font-bcnbook rounded-md p-2 focus:outline-none focus:ring-2 focus:ring-lgreen focus:border-transparent"
            />
            <label htmlFor="password">Password</label>
            <input
              type="password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              id="password"
              name="password"
              placeholder="Enter Password"
              className="w-96 font-bcnbook rounded-md p-2 focus:outline-none focus:ring-2 focus:ring-lgreen focus:border-transparent"
            />
            <label htmlFor="confirmPassword">Confirm Password</label>
            <input
              type="password"
              value={confirmPassword}
              onChange={(e) => setConfirmPassword(e.target.value)}
              id="confirmPassword"
              name="confirmPassword"
              placeholder="Confirm Password"
              className="w-96 font-bcnbook rounded-md p-2 focus:outline-none focus:ring-2 focus:ring-lgreen focus:border-transparent"
            />
            <button
              type="submit"
              className="bg-lgreen text-white font-bcnsb py-2 rounded-md"
            >
              Update
            </button>
          </form>
        </div>
      </div>
    </>
  );
};

export default ProfileEdit;
