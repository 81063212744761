import React from "react";

const Terms = () => {
  return (
    <div className="max-w-4xl mx-auto px-4 py-8 font-bcnsb">
      <h1 className="text-3xl font-bold text-center mb-6">
        Terms & Conditions
      </h1>
      <p className="mb-4">
        <strong>Effective Date:</strong> 12 May 2024
      </p>

      <h2 className="text-2xl font-semibold mt-6 mb-2">1. Introduction</h2>
      <p className="mb-4">
        Welcome to Invest With Certainty! By accessing and using
        www.investwithcertainty.com (“the Website”), including all content and
        functionality available through the domain, you accept and agree to be
        bound by these Terms & Conditions, which govern your access to and use
        of our services, including our options trading masterclasses. If you do
        not agree to these terms, please do not use our website.
      </p>

      <h2 className="text-2xl font-semibold mt-6 mb-2">
        2. Intellectual Property
      </h2>
      <p className="mb-4">
        The content, graphics, design, compilations, magnetic translation,
        digital conversion, and other matters related to the Website are
        protected under applicable copyrights, trademarks, and other proprietary
        rights. The logo, brand, and name of Invest With Certainty are owned by
        the company and may not be used in connection with any product or
        service without the prior written consent of Invest With Certainty.
      </p>

      <h2 className="text-2xl font-semibold mt-6 mb-2">3. Use of the Site</h2>
      <h3 className="text-xl font-semibold mt-4 mb-2">3.1 Permissible Use:</h3>
      <p className="mb-4">
        You are granted a non-exclusive, non-transferable, revocable license to
        access and use the Website strictly in accordance with these Terms &
        Conditions.
      </p>
      <h3 className="text-xl font-semibold mt-4 mb-2">3.2 Prohibited Uses:</h3>
      <p className="mb-4">
        You may not use the Website for any purpose that is unlawful or
        prohibited by these Terms, or to solicit the performance of any illegal
        activity or other activity which infringes the rights of Invest With
        Certainty or others. Prohibited activities include:
      </p>
      <ul className="list-disc list-inside mb-4">
        <li>
          Harassing or causing distress or inconvenience to any other user.
        </li>
        <li>Transmitting obscene or offensive content.</li>
        <li>Disrupting the normal flow of dialogue within the Website.</li>
        <li>
          Unauthorized access or bypassing the measures we may use to prevent or
          restrict access to the Website.
        </li>
      </ul>

      <h2 className="text-2xl font-semibold mt-6 mb-2">
        4. Account Registration and Security
      </h2>
      <p className="mb-4">You agree to:</p>
      <ul className="list-disc list-inside mb-4">
        <li>
          Provide accurate, current, and complete information about yourself as
          may be prompted by any registration forms on the Website.
        </li>
        <li>Maintain the security of your password and identification.</li>
        <li>
          Accept all risks of unauthorized access to the registration data and
          any other information you provide to Invest With Certainty.
        </li>
      </ul>

      <h2 className="text-2xl font-semibold mt-6 mb-2">
        5. Masterclasses and Purchases
      </h2>
      <h3 className="text-xl font-semibold mt-4 mb-2">
        5.1 Masterclass Enrollment:
      </h3>
      <p className="mb-4">
        Fees for masterclasses and any other services offered on the Website are
        stated in U.S. Dollars unless otherwise specified and are subject to
        change without notice.
      </p>
      <h3 className="text-xl font-semibold mt-4 mb-2">
        5.2 Refunds and Cancellations:
      </h3>
      <p className="mb-4">
        Refunds and cancellations are available according to the terms specified
        at the time of purchase. Typically, a cancellation fee may apply, and
        refunds may only be issued under specific conditions.
      </p>

      <h2 className="text-2xl font-semibold mt-6 mb-2">
        6. Third-Party Links and Services
      </h2>
      <p className="mb-4">
        The Website may contain links to other websites ("Linked Sites"). The
        Linked Sites are not under the control of Invest With Certainty and we
        are not responsible for the contents of any Linked Site, including
        without limitation any link contained in a Linked Site, or any changes
        or updates to a Linked Site.
      </p>

      <h2 className="text-2xl font-semibold mt-6 mb-2">
        7. Limitation of Liability
      </h2>
      <p className="mb-4">
        Invest With Certainty will not be liable for any damages of any kind
        arising from the use of this site or from any information, content,
        materials, products, or services included on or otherwise made available
        to you through this site, including direct, indirect, incidental,
        punitive, and consequential damages.
      </p>

      <h2 className="text-2xl font-semibold mt-6 mb-2">8. Indemnification</h2>
      <p className="mb-4">
        You agree to indemnify, defend, and hold harmless Invest With Certainty,
        its officers, directors, employees, agents, licensors, suppliers, and
        any third-party information providers to the Website from and against
        all losses, expenses, damages, and costs, including reasonable
        attorneys' fees, resulting from any violation of this Agreement
        (including negligent or wrongful conduct) by you or any other person
        accessing the Website.
      </p>

      <h2 className="text-2xl font-semibold mt-6 mb-2">
        9. Dispute Resolution
      </h2>
      <p className="mb-4">
        Any disputes related to your use of the Website or these Terms shall be
        resolved by arbitration in [Insert Jurisdiction], except that, to the
        extent you have in any manner violated or threatened to violate Invest
        With Certainty's intellectual property rights, Invest With Certainty may
        seek injunctive or other appropriate relief in any state or federal
        court.
      </p>

      <h2 className="text-2xl font-semibold mt-6 mb-2">10. Changes to Terms</h2>
      <p className="mb-4">
        Invest With Certainty reserves the right, at its sole discretion, to
        change, modify, add or remove portions of these Terms & Conditions, at
        any time. It is your responsibility to check these Terms periodically
        for changes. Your continued use of the Website following the posting of
        changes will mean that you accept and agree to the changes.
      </p>

      <h2 className="text-2xl font-semibold mt-6 mb-2">
        11. Contact Information
      </h2>
      <p>
        If you have any questions or comments about these Terms & Conditions,
        please contact us at [Insert Email], [Insert Phone Number], or [Insert
        Mailing Address].
      </p>
    </div>
  );
};

export default Terms;
