import { PAYMENT_URL } from "../constants";
import { apiSlice } from "./apiSlice";

export const paymentsApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    createCheckoutSession: builder.mutation({
      query: (data) => ({
        url: PAYMENT_URL + "/checkout-session",
        method: "POST",
        body: data,
      }),
    }),
    checkIfPaid: builder.mutation({
      query: (email) => ({
        url: PAYMENT_URL + "/check-if-paid/" + email,
        method: "POST",
      }),
      keepUnusedDataFor: 5,
    }),
    cancelSubscription: builder.mutation({
      query: (email) => ({
        url: PAYMENT_URL + "/cancel/" + email,
        method: "POST",
      }),
      keepUnusedDataFor: 5,
    }),
    checkSubscriptionStatus: builder.mutation({
      query: (data) => ({
        url: PAYMENT_URL + "/check-sub",
        method: "POST",
        body: data,
      }),
    }),
  }),
});

export const {
  useCreateCheckoutSessionMutation,
  useCheckIfPaidMutation,
  useCancelSubscriptionMutation,
  useCheckSubscriptionStatusMutation,
} = paymentsApiSlice;
