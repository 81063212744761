import React from "react";
import investorImage from "../images/traderpicture.png";
import "./Components.css";

const InvestorInfo = () => {
  return (
    <div id="trader-info" className="w-full h-full mt-5 pt-5 mb-10">
      <div className="md:text-5xl text-3xl text-black font-bcneb grid grid-cols-1 place-items-center candle-bg bg-opacity-10 bg-cover mb-10">
        <div className="m-2 flex items-center text-center">
          <h2 className="">
            MADE BY SELF TAUGHT TRADERS FOR SELF LEARNING TRADERS
          </h2>
        </div>
        <div className="m-2 items-center font-bcnsb grid md:grid-rows-7 p-2 text-center text-2xl md:w-3/4 w-full">
          <p className="">
            These webinars are conducted by IWC’s experienced traders that have
            achieved:
          </p>
          <p>10+ Years Combined Trading Experience</p>
          <p>Zero Capital To 6 figures In Under 3 Years</p>
          <p>Portfolio Growths Of Up To 4000%</p>
          <p>
            Together, the team has helped hundreds of people to not only grow
            their trading portfolios,
          </p>
          <p>
            but also helped them quickly set up a passive stream of income with
            the right knowledge.
          </p>
          <p>
            They will be showing you how to model their methods and achieve
            similar results… Even if you’re a complete beginner that has the
            slightest clue about what investing is all about!
          </p>
        </div>
        <div>
          <img src={investorImage} alt="traders" />
        </div>
      </div>
    </div>
  );
};

export default InvestorInfo;
