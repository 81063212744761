import React from "react";
import OneKLogo from "../images/1KLogo.png";
import Waves from "../images/waves.png";
import "./Components.css";

const OneKBanner = () => {
  return (
    <div className="w-full">
      <div className="grid md:grid-rows-3 items-center">
        <section
          id="hero"
          className="w-full h-full grid md:grid-cols-2 gap-3 row-span-2 items-center justify-items-center md:p-10 sm:p-2 md:px-5 mb-5"
        >
          <div className="flex items-center pt-10">
            <div className="flex justify-center">
              <img
                src={OneKLogo}
                className="w-3/4 md:w-full xl:w-full place-self-center"
              ></img>
            </div>
          </div>
          <div className="w-full grid grid-rows-1 md:justify-items-start justify-items-center">
            <h1 className="md:text-5xl text-3xl md:text-left text-center w-full font-bcneb mb-4">
              Learn The Fundamental Rules, Knowledge and Truths of Trading
            </h1>
            <h5 className="text-xl md:text-left text-center w-3/4 font-bcnbook mb-4">
              At An Accelerated & Consistent Rate...
            </h5>
            <a href="/join">
              <button
                className="w-96 h-16 text-white text-left flex p-5 font-bcnsb 
          bg-[conic-gradient(#03D38B,#10917B,#356C59,#400E32,#A61F69,#C5478C,#8CEBCB,#03D38B)] hover:opacity-50 rounded-lg"
              >
                <div className="w-full grid grid-cols-2 place-self-center">
                  <div className="self-center">
                    <p className="mt-1">JOIN NOW</p>
                  </div>
                  <div className="place-self-end">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      strokeWidth={1.5}
                      stroke="currentColor"
                      className="w-10 h-10"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        d="m12.75 15 3-3m0 0-3-3m3 3h-7.5M21 12a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z"
                      />
                    </svg>
                  </div>
                </div>
              </button>
            </a>
          </div>
        </section>
        <section id="waves" className="">
          <img src={Waves} className="object-contain lg:w-full"></img>
        </section>
      </div>
    </div>
  );
};

export default OneKBanner;
