import React from "react";
import "./Components.css";
import Bank from "../images/bank.png";
import GoldenEgg from "../images/golden-egg.png";
import Money from "../images/money.png";
import Report from "../images/report.png";
import Savings from "../images/savings.png";
import Startup from "../images/startup.png";
import Target from "../images/target.png";

const Benefits = () => {
  return (
    <div
      id="benefits"
      className="w-full h-full text-center text-white grid grid-rows-2 grid-cols-2 gap-5 md:pt-20 justify-center items-center font-bcnsb p-5 pb-[20%] bg-banner bg-no-repeat bg-center bg-cover"
    >
      <div className="flex justify-self-center flex-col md:w-1/2 md:m-5">
        <img
          src={Money}
          alt="money"
          className="md:w-36 invert w-16 place-self-center md:mb-10 mb-3"
        ></img>
        <p className="md:text-2xl text-sm">
          Comprehending and applying real money and wealth building methods.
        </p>
      </div>
      <div className="flex justify-self-center flex-col md:w-1/2 md:m-5">
        <img
          src={GoldenEgg}
          alt="golden egg"
          className="md:w-36 w-16 invert place-self-center md:mb-10 mb-3"
        ></img>
        <p className="md:text-2xl text-sm">
          Students achieve high returns via supportive community.
        </p>
      </div>
      <div className="flex justify-self-center flex-col md:w-1/2 md:m-5">
        <img
          src={Target}
          alt="target"
          className="md:w-36 w-16 invert place-self-center md:mb-10 mb-3"
        ></img>
        <p className="md:text-2xl text-sm">
          Distinguish between strategy and tactics in trading.
        </p>
      </div>
      <div className="flex justify-self-center flex-col md:w-1/2 md:m-5">
        <img
          src={Report}
          alt="report"
          className="md:w-36 w-16 invert place-self-center md:mb-10 mb-3"
        ></img>
        <p className="md:text-2xl text-sm">
          Essential fundamentals, mindset for consistent portfolio performance.
        </p>
      </div>
      <div className="flex justify-self-center flex-col md:w-1/2 md:m-5">
        <img
          src={Savings}
          alt="savings"
          className="md:w-36 w-16 invert place-self-center md:mb-10 mb-3"
        ></img>
        <p className="md:text-2xl text-sm">
          Boost portfolio growth with minimal initial investment.
        </p>
      </div>
      <div className="flex justify-self-center flex-col md:w-1/2 md:m-5">
        <img
          src={Startup}
          alt="startup"
          className="md:w-36 w-16 invert place-self-center md:mb-10 mb-3"
        ></img>
        <p className="md:text-2xl text-sm">
          Customize investment strategy for individual success factors.
        </p>
      </div>
      <div className="flex justify-self-center flex-col col-span-2 w-1/2 md:m-5">
        <img
          src={Bank}
          alt="bank"
          className="md:w-36 w-16 invert place-self-center md:mb-10 mb-3"
        ></img>
        <p className="md:text-2xl text-sm">
          Consistently earn despite market conditions through expertise.
        </p>
      </div>

      {/* <div className="w-full grid h-full md:grid-rows-3 grid-cols-1 md:gap-5 gap-2 items-center p-5 mt-5">
        <div className="grid md:grid-cols-5 md:grid-rows-3 grid-cols-1 items-center">
          <div className="grid md:w-1/2 w-full justify-self-end md:col-span-2 md:grid-rows-2 grid-cols-1">
            <div className="place-self-center">
              <img src={Money} alt="money" className="md:w-48 w-24"></img>
            </div>
            <div className="w-3/4 justify-self-center mt-5">
              <p className="text-2xl">
                Comprehending and applying real money and wealth building
                methods.
              </p>
            </div>
          </div>
          <div className="grid md:grid-rows-2 grid-cols-1">
            <div className="place-self-center">
              <img
                src={GoldenEgg}
                alt="goldenegg"
                className="md:w-48 w-24"
              ></img>
            </div>
            <div className="w-3/4 justify-self-center mt-5">
              <p className="text-2xl">
                Students achieve high returns via supportive community.
              </p>
            </div>
          </div>
          <div className="grid md:col-span-2 justify-self-start md:w-1/2 w-full grid-rows-2">
            <div className="place-self-center">
              <img src={Target} alt="target" className="md:w-48 w-24"></img>
            </div>
            <div className="w-3/4 justify-self-center mt-5">
              <p className="text-2xl">
                Distinguish between strategy and tactics in investing.
              </p>
            </div>
          </div>
        </div>
        <div className="grid md:grid-cols-5 md:grid-rows-3 grid-cols-1 items-center">
          <div className="grid w-1/2 justify-self-end col-span-2 grid-rows-2">
            <div className="place-self-center">
              <img src={Report} alt="report" className="md:w-48 w-24"></img>
            </div>
            <div className="w-3/4 justify-self-center mt-5">
              <p className="text-2xl">
                Comprehending and applying real money and wealth building
                methods.
              </p>
            </div>
          </div>
          <div className="grid grid-rows-2">
            <div className="place-self-center">
              <img src={Savings} alt="savings" className="md:w-48 w-24"></img>
            </div>
            <div className="w-3/4 justify-self-center mt-5">
              <p className="text-2xl">
                Comprehending and applying real money and wealth building
                methods.
              </p>
            </div>
          </div>
          <div className="grid col-span-2 justify-self-start w-1/2 grid-rows-2">
            <div className="place-self-center">
              <img src={Startup} alt="startup" className="md:w-48 w-24"></img>
            </div>
            <div className="w-3/4 justify-self-center mt-5">
              <p className="text-2xl">
                Comprehending and applying real money and wealth building
                methods.
              </p>
            </div>
          </div>
        </div>
        <div className="grid md:grid-cols-5 md:grid-rows-3 grid-cols-1 items-center">
          <div className="grid w-1/2 justify-self-center grid-rows-2">
            <div className="place-self-center">
              <img src={Bank} alt="bank" className="md:w-48 w-24"></img>
            </div>
            <div className="w-3/4 justify-self-center mt-5 ">
              <p className="text-2xl">
                Comprehending and applying real money and wealth building
                methods.
              </p>
            </div>
          </div>
        </div>
      </div> */}
    </div>
  );
};

export default Benefits;
