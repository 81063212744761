import React, { useState } from "react";
import { Document, Page, pdfjs } from "react-pdf";

export default function PDFViewer(samplePDF) {
  const [numPages, setNumPages] = useState(null);
  const [pageNumber, setPageNumber] = useState(1);

  function onDocumentLoadSuccess({ numPages }) {
    setNumPages(numPages);
    setPageNumber(1);
  }

  function changePage(offset) {
    setPageNumber((prevPageNumber) => prevPageNumber + offset);
  }

  function previousPage() {
    changePage(-1);
  }

  function nextPage() {
    changePage(1);
  }

  return (
    <>
      <div className="w-full grid grid-rows-2 ">
        <p className="text-center font-bcnsb text-2xl">
          Page {pageNumber || (numPages ? 1 : "--")} of {numPages || "--"}
        </p>
        <div className="text-4xl grid grid-cols-2">
          <button
            type="button"
            className="w-full"
            disabled={pageNumber <= 1}
            onClick={previousPage}
          >
            <ion-icon name="arrow-back-circle-outline"></ion-icon>
          </button>
          <button
            type="button"
            className="w-full "
            disabled={pageNumber >= numPages}
            onClick={nextPage}
          >
            <ion-icon name="arrow-forward-circle-outline"></ion-icon>
          </button>
        </div>
      </div>
      <div className="border shadow">
        <Document
          file={samplePDF.samplePDF}
          onLoadSuccess={onDocumentLoadSuccess}
          pageLayout="oneColumn"
        >
          <Page
            pageNumber={pageNumber}
            className="hover:scale-125 transition duration-300"
          />
        </Document>
      </div>
    </>
  );
}

// export default PDFViewer;
